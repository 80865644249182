import React from 'react'
import useCanvas from '../hooks/useCanvas'

const Canvas = props => {  
    const { draw, onClick, onContextMenu, onMouseMove, flag, ...rest } = props
    const canvasRef = useCanvas(draw)

    const handleClick = (e) => {
        onClick(e, canvasRef);
    }

    const handleLongPress = (e) => {
        e.preventDefault();
        onContextMenu(e, canvasRef);
    }

    const handleCanvasMouseMove = (e) => {
        if (onMouseMove) {
            onMouseMove(e, canvasRef);
        }
    }

    return <canvas ref={canvasRef} onClick={handleClick} 
        onContextMenu={handleLongPress} onMouseMove={handleCanvasMouseMove} {...rest}/>
}

export default Canvas