import React from 'react';
import { connect } from 'react-redux';
import { socketConnect } from './redux/socket/socket';

class SocketConnection extends React.Component {

    componentDidMount() {
        const { dispatch, host } = this.props;
        dispatch(socketConnect(host));
    }

    render() {
        return <div>{this.props.children}</div>
    }

}

export default connect()(SocketConnection);