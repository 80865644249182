import { configureStore } from '@reduxjs/toolkit'
import settingsReducers from './settings/settingsSlice';
import clientReducers from './client/clientSlice';
import dealsReducers from './deals/dealsSlice';
import geometryReducers from './geometry/geometrySlice';
import modalRedusers from './modal/modalSlice';
import socketMiddleware from '../middleware/middleware';
import { apiSlice } from './apiSlice'

export default configureStore({
  reducer: {
      settings: settingsReducers,
      clients: clientReducers,
      deals: dealsReducers,
      geometry: geometryReducers,
      modals: modalRedusers,
      [apiSlice.reducerPath]: apiSlice.reducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
  }).concat(socketMiddleware()).concat(apiSlice.middleware),
})
