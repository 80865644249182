import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { getActiveDeal, setActiveDeal } from '../../redux/deals/dealsSlice';
import { selectGeometry, resetGeometry, resetLimit } from '../../redux/geometry/geometrySlice';
import { isDealChangeEnabled } from '../../helpers/deal-state-check';
import { useGetDealTypeQuery, useGetDealTypesQuery } from '../../api/dealTypeApi';

const DealTypeSelector = (props) => {
    const dispatch = useDispatch();
    const deal = useSelector(getActiveDeal);
    const { data: dealTypes, isSuccess: isIndexSuccess } = useGetDealTypesQuery()
    const { data: dealType, error, isSuccess: isTypeSuccess } = 
        useGetDealTypeQuery(deal?.dealTypeId, { skip: !deal?.dealTypeId || deal?.dealTypeId == null }) 
    const geometry = useSelector(selectGeometry);

    useEffect(
        () => {
            if (isTypeSuccess && !error) {
                dispatch(setActiveDeal({...deal, deal_type: dealType }));
            }
        }, [dealType, isTypeSuccess, error]
    )

    const activeDealTypeName = () => {
        if (deal) {
            if (deal?.deal_type != 0) {
                if (dealType?.title) {
                    return dealType?.title
                }
                if (deal.deal_type?.title) {
                    return deal.deal_type?.title
                }
                return "Выберите расклад"
            }
        }
        return "Выберите расклад"
    }

    const handleDealTypeSelect = (i) => {
        dispatch(resetGeometry()); 
        dispatch(resetLimit()); 
        dispatch(setActiveDeal({...deal, dealTypeId: i }));
    }

    return(
        <DropdownButton
            onSelect={handleDealTypeSelect}
            title={activeDealTypeName()}
            size="sm"
            disabled={!isDealChangeEnabled(geometry)}
            className="pb-2 tarot-dropdown"
            variant={"primary"}
            data-testid="tarot-deal-types-selector"
        >
            {dealTypes?.map((item, index) => {
                return <Dropdown.Item 
                    key={index} 
                    eventKey={item?.id}
                    data-testid="tarot-deal-type"
                >
                    {item?.title}
                </Dropdown.Item>
            })}
        </DropdownButton>
    )
}

export default DealTypeSelector;