import React, { useContext, useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ExpandedContext } from '../expanded-context';
import { getKeycloak, changeTheme, setUserAvatar, setUserEmail, 
    setUserGivenName, setUserFamilyName, setUserPhone, setUserCountry, 
    fetchCatalog, fetchOverdue, setUserUsername, selectAccount, 
    selectAvatar } from '../redux/settings/settingsSlice';
import { FaUpload, FaPencilAlt, FaSave, FaTrash } from "react-icons/fa";
import { BiZoomIn } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Toast from 'react-bootstrap/Toast';
import * as utils from '../helpers/utils';
import AvatarEditor from 'react-avatar-editor'
import Country from './Country';
import useOnClickOutside from '../hooks/useOnClickOutside';
import Field from './Field';
import { useGetSelfAccountQuery, useGetCatalogQuery, 
    useUpdateAvatarMutation, useUpdateSelfDataMutation } from '../api/accountApi';

import '../scss/custom.scss';
import "react-datepicker/dist/react-datepicker.css";
import '../css/tile.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import '../css/nkp.css';

const { TAROT_API_URL } = process.env;

const UserProfile = (props) => {
    const [expanded, setExpanded] = useContext(ExpandedContext);
    const { data: existingAccount, isSuccess: isAccountSuccess } = useGetSelfAccountQuery()
    const account = useSelector(selectAccount);
    const avatar = useSelector(selectAvatar);
    const [scale, setScale] = useState(1);
    const [editorActive, setEditorActive] = useState(false);
    const [editEmail, setEditEmail] = useState(false);
    const [editUsername, setEditUsername] = useState(false);
    const [editGivenName, setEditGivenName] = useState(false);
    const [editFamilyName, setEditFamilyName] = useState(false);
    const [editCountry, setEditCountry] = useState(false);
    const [editPhone, setEditPhone] = useState(false);
    const [editUsernameVisible, setEditUsernameVisible] = useState(false);
    const [editAvaVisible, setEditAvaVisible] = useState(false);
    const [editEmailVisible, setEditEmailVisible] = useState(false);
    const [editCountryVisible, setEditCountryVisible] = useState(false);
    const [editGivenNameVisible, setEditGivenNameVisible] = useState(false);
    const [editFamilyNameVisible, setEditFamilyNameVisible] = useState(false);
    const [editPhoneVisible, setEditPhoneVisible] = useState(false);
    const [avaChanged, setAvaChanged] = useState(false);
    const authData = useSelector(getKeycloak);
    const authStatus = useSelector(state => state.settings.authenticated);
    const odStatus = useSelector(state => state.settings.od_status);
    const overdue = useSelector(state => state.settings.overdue);
    const changeInProgress = useSelector(state => state.settings.changeInProgress);
    const [toast, setToast] = useState({});
    const [showToast, setShowToast] = useState(false);
    const [updateAvatar, { isUpdateLoading }] = useUpdateAvatarMutation()
    const [updateSelfData, { isUpdateSelfLoading }] = useUpdateSelfDataMutation()

    const editorRef = useRef(null)
    const avaRef = useRef();

    useOnClickOutside(avaRef, () => handleCancelAva());

    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        if (odStatus === 'idle' && authStatus === true) {
            dispatch(fetchOverdue(authData))
        }
    }, [odStatus, authStatus, dispatch])

    const handleThemeChange = (theme) => {
        dispatch(changeTheme(theme));
    }

    const handleNewImage = (e) => {
        let reader = new FileReader();
        reader.onload = function(e) {
          dispatch(setUserAvatar(e.target.result))
        };
        reader.readAsDataURL(e.target.files[0])
    }

    const handleScale = e => {
        const newScale = parseFloat(e.target.value)
        setScale(newScale)
    }

    const handleSaveAva = (e) => {
        let editor = editorRef.current;
        const canvasScaled = editor.getImageScaledToCanvas();
        const croppedImg = canvasScaled.toDataURL();
        setAvaChanged(true)
        dispatch(setUserAvatar(croppedImg))
        setEditorActive(false)
    };

    const handleCancelAva = (e) => {
        dispatch(setUserAvatar(null))
        setAvaChanged(false)
        setEditorActive(false)
    };

    const handleSave = async () => {
        if (avaChanged) {
            try {
                await updateAvatar(account.avatarAlt).unwrap()
            } catch {
              setToast({
                title: 'Не получилось...',
                description: "Не удалось загрузить аватар, пожалуйста попробуйте еще раз.",
                status: 'error'
              })
              setShowToast(true)
            }
            setAvaChanged(false);
        }
        try {
            await updateSelfData(account).unwrap()
            setEditorActive(false);
            setEditCountry(false);
            setEditEmail(false);
            setEditFamilyName(false);
            setEditGivenName(false);
            setEditPhone(false);
            setEditUsername(false);   
        } catch {
          setToast({
            title: 'Не получилось...',
            description: "Не удалось сохранить, пожалуйста попробуйте еще раз.",
            status: 'error'
          })
          setShowToast(true)
        }
    }

    const handleSubscribe = () => {
        history.push("/subscribe")
    }

    const handleEditAll = () => {
        setEditorActive(true);
        setEditCountry(true);
        setEditEmail(true);
        setEditFamilyName(true);
        setEditGivenName(true);
        setEditPhone(true);
        setEditUsername(true);
    }

    const handleCancel = () => {
        dispatch(refreshUser(authData));
        setEditorActive(false);
        setEditCountry(false);
        setEditEmail(false);
        setEditFamilyName(false);
        setEditGivenName(false);
        setEditPhone(false);
        setEditUsername(false);   
    }

    const userData = () => {
        let message
        switch (account?.product?.phase) {
            case "TRIAL":
                message = `Подписка активна. Вам предоставлен пробный бесплатный период на 1 месяц. Дата первого списания абонентской платы - ${utils.formatDate(new Date(account?.product?.billed_till))}`;
                break;
            case "ACTIVE":
                message = `Подписка активна. Дата ближайшего списания абонентской платы - ${utils.formatDate(new Date(account?.product?.billed_till))}`;
                break;
            case "OVERDUE":
                message = `Подписка активна, но пропущена дата списания абонентской платы. Если не оплатить ее до ${utils.shiftDate(new Date(account?.product?.billed_till), overdue?.warning_threshold || 0)}, сервис будет ограничен.`;
                break;
            case "SUSPENDED":
                message = `Подписка приостановлена. Если не возобновить ее до ${utils.shiftDate(new Date(account?.product?.billed_till), overdue?.block_threshold || 0)}, ваши данные будут удалены`;
                break;
            case "BLOCKED":
                message = `Подписка неактивна. В связи с длительным периодом неуплаты ваши данные были удалены, доступ предоставляется в демо-режиме.`;
                break;
            default:
                message = `Подписка неактивна, доступ предоставляется в демо-режиме.`;
        }
        return message
    }

    const sanitizeValue = (value) => {
        if (!value) { return "" }

        return value
    }

    const productLevel = () => {
        {
            if (changeInProgress) {
                return (
                    <div data-testid="tarot-subscribe-status">
                        В настоящее время происходит смена уровня подписки, пожалуйста немного подождите.
                    </div>
                )
            }

            return (
                <>
                    {account?.product?.name == "DEMO" ?
                        <>
                        <div data-testid="tarot-subscribe-status">
                            Подписка неактивна, приложение работает в демо-режиме.
                        </div>
                        <div data-testid="tarot-subscribe-button">
                            <label id="theme-switch" onClick={handleSubscribe}>Оформить подписку</label>
                        </div>
                        </>
                        :
                        <>
                        <div data-testid="tarot-subscribe-status">
                            <b>{account?.product?.pretty_name}</b>
                        </div>
                        {/* <div>
                            {product?.description}
                        </div> */}
                        <div data-testid="tarot-subscribe-button">
                            <label id="theme-switch" onClick={handleSubscribe}>Сменить тариф</label>
                        </div>
                        </>
                    }
                </>
            )
        }

    }

    return (
        <div className={expanded ? "tarot-content-expanded" : "tarot-content"}>
            {!isAccountSuccess ?
            <div id="tarology-spinner" data-testid="tarot-spinner">
                <Spinner animation="border" />
            </div>
            :
            <>
            <div className="content mt-3">
                {editorActive ?
                <div id="user-profile">
                    <div id="user-avatar-bed" data-testid="tarot-avatar-bed"
                        onMouseEnter={() => setEditAvaVisible(true)}
                        onMouseLeave={() => setEditAvaVisible(false)}                 
                    >
                    <div id="user-profile-avatar" className="edit" ref={avaRef}>
                        <AvatarEditor
                            className='avatar-editor'
                            crossOrigin='anonymous'
                            image={avatar}
                            width={170}
                            height={170}
                            border={15}
                            borderRadius={500}
                            color={[255, 255, 255, 0.9]} // RGBA
                            scale={parseFloat(scale)}
                            rotate={0}
                            ref={editorRef}
                        />
                            <BiZoomIn className='avatar-editor-zoom'/>
                            <input
                                name="scale"
                                type="range"
                                onChange={handleScale}
                                min="0.1"
                                max="5"
                                step="0.01"
                                defaultValue="1"
                            />
                            <center>
                                <label htmlFor="avatar-pic">
                                    <FaUpload /> Загрузить фото
                                    <input id="avatar-pic" className="tarot-sex-button mt-1" name="newImage" type="file" onChange={handleNewImage} /> 
                                </label>
                            </center>
                    {editAvaVisible && <FaSave className='edit-avatar' onClick={handleSaveAva} /> }
                </div>
                </div>
                    <div id="user-profile-name" data-testid="tarot-profile-name">
                        {account?.user_name}
                    </div>
                </div>
                :
                <div id="user-profile">
                    <div id="user-avatar-bed" data-testid="tarot-avatar-bed"
                        onMouseEnter={() => setEditAvaVisible(true)}
                        onMouseLeave={() => setEditAvaVisible(false)}                                       
                    >
                    <div id="user-profile-avatar" data-testid="tarot-profile-avatar">
                            <img src={avatar} />
                            {editAvaVisible && <FaPencilAlt className='edit-avatar' onClick={() => setEditorActive(!editorActive)} />}
                    </div>
                    </div>
                    <div id="user-profile-name" data-testid="tarot-profile-name"
                        onMouseEnter={() => setEditUsernameVisible(true)}
                        onMouseLeave={() => setEditUsernameVisible(false)}                                                           
                    >
                        {editUsername ?
                            <>
                                <input 
                                    type="text" 
                                    value={sanitizeValue(account?.user_name)} 
                                    onChange={(e) => dispatch(setUserUsername(e.target.value))}
                                    //onBlur={() => setEditUsername(!editUsername)}

                                />
                                <FaPencilAlt className='edit-username' onClick={() => setEditUsername(!editUsername)} />
                            </>
                        :
                            <>
                                {account?.user_name}
                                {editUsernameVisible && <FaPencilAlt className='edit-username' onClick={() => setEditUsername(!editUsername)} />}
                            </>
                        }
                    </div>
                </div>}
                <div style={{ marginRight: '-10px' }} data-testid="tarot-profile-edit-button">
                    <center>
                        <Button className="tarot-sex-button mr-3 button-mobile-only" size="sm" onClick={handleEditAll}><FaPencilAlt/> Изменить</Button>
                    </center>
                </div>
                <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide>
                    <Toast.Header>
                        <strong className="me-auto">{toast?.title}</strong>
                    </Toast.Header>
                    <Toast.Body>{toast?.description}</Toast.Body>
                </Toast>
                <div id="user-profile-data">
                    <Row className="mb-5 w-100">
                        <Col className="content">
                            <span>Контактная информация</span>
                            <div id="profile-section">
                                <Field  mouseEvent={setEditGivenNameVisible} 
                                        flagVisible={editGivenNameVisible} 
                                        enable={setEditGivenName} 
                                        flag={editGivenName}
                                        label={"Имя"}
                                        value={sanitizeValue(account?.name)}
                                        action={setUserGivenName}
                                        data-testid="tarot-profile-field-name"
                                />
                                <Field  mouseEvent={setEditFamilyNameVisible} 
                                        flagVisible={editFamilyNameVisible} 
                                        enable={setEditFamilyName} 
                                        flag={editFamilyName}
                                        label={"Фамилия"}
                                        value={sanitizeValue(account?.surname)}
                                        action={setUserFamilyName}
                                        data-testid="tarot-profile-field-surname"
                                />
                            <div className='field'
                                onMouseEnter={() => setEditCountryVisible(true)}
                                onMouseLeave={() => setEditCountryVisible(false)}
                            >
                                <div className='label'>
                                    Страна:
                                </div>
                                <div className='data' data-testid="tarot-profile-field-country">
                                    {editCountry ?
                                    <select value={sanitizeValue(account?.country)}
                                        onChange={(e) => dispatch(setUserCountry(e.target.value))}
                                        //onBlur={() => setEditCountry(!editCountry)}
                                    >
                                        {Object.keys(Country).map((key, index) => {
                                            return (
                                                <option key={index} value={key}>{Country[key]}</option>
                                            )
                                        })}
                                    </select>
                                    :
                                    Country[account?.country]}
                                    {editCountryVisible && <FaPencilAlt className='edit-field' onClick={() => setEditCountry(!editCountry)} /> }
                                </div>
                            </div>
                                <Field  mouseEvent={setEditEmailVisible} 
                                        flagVisible={editEmailVisible} 
                                        enable={setEditEmail} 
                                        flag={editEmail}
                                        label={"E-mail"}
                                        value={sanitizeValue(account?.email)}
                                        action={setUserEmail}
                                        data-testid="tarot-profile-field-email"
                                />
                                <Field  mouseEvent={setEditPhoneVisible} 
                                        flagVisible={editPhoneVisible} 
                                        enable={setEditPhone} 
                                        flag={editPhone}
                                        label={"Телефон"}
                                        value={sanitizeValue(account?.phone)}
                                        action={setUserPhone}
                                        data-testid="tarot-profile-field-phone"
                                />
                            </div>
                        </Col>
                        <Col className="content">
                            <span>Статус подписки</span>
                            <div id="profile-section">
                            <div className='field'>
                                <div className='label'>
                                    Уровень подписки:
                                </div>
                                <div className='data' data-testid="tarot-profile-level">
                                    {productLevel()}
                                </div>
                            </div>
                            <div className='field'>
                                <div className='label'>
                                    Баланс:
                                </div>
                                <div className='data' data-testid="tarot-profile-balance">
                                    {`${account?.balance == null ? 0 : account?.balance.toFixed(2)} ${account?.currency}`}
                                </div>
                            </div>
                            <div className='field'>
                                <div className='label'>
                                    Текущий статус:
                                </div>
                                <div className='data' data-testid="tarot-profile-status">
                                    {userData()}
                                </div>
                            </div>
                            </div>
                            <span>Настройки</span>
                            <div id="profile-section">
                            <div className='field'>
                                <div className='label' style={{ display: 'flex', alignItems: "center" }}>
                                    Цветовая тема:
                                </div>
                                <div className='data'>
                                    <label id="theme-switch" data-testid="tarot-theme" onClick={() => handleThemeChange("blue")}>Синяя</label>
                                    <label id="theme-switch" data-testid="tarot-theme" onClick={() => handleThemeChange("green")}>Зеленая</label>
                                    <label id="theme-switch" data-testid="tarot-theme" onClick={() => handleThemeChange("red")}>Красная</label>
                                    <label id="theme-switch" data-testid="tarot-theme" onClick={() => handleThemeChange("orange")}>Оранжевая</label>
                                    <label id="theme-switch" data-testid="tarot-theme" onClick={() => handleThemeChange("black")}>Черно-белая</label>
                                </div>
                            </div>
                            </div>
                        </Col>
                    </Row> 
                    <Row className="profile-buttons">
                        <Col className="content">
                            <div>
                                {(isUpdateLoading || isUpdateSelfLoading) ?
                                <Button className="tarot-sex-button mr-3" size="sm" disabled={true}>
                                    <Spinner 
                                        as="span"
                                        variant="light"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        animation="border"
                                        data-testid="tarot-save-client-spinner"
                                    /> Сохраняю...
                                </Button>
                                :
                                <Button className="tarot-sex-button mr-3" size="sm" 
                                    onClick={handleSave} data-testid="tarot-profile-button-save">
                                    <FaSave/> Сохранить
                                </Button>}
                                <Button className="tarot-red-button" size="sm" 
                                    onClick={handleCancel} data-testid="tarot-profile-button-cancel">
                                    <FaTrash/> Отменить
                                </Button>
                            </div>
                        </Col>
                    </Row>       
                </div>                
            </div>
            </>}
        </div>
    );
}

export default UserProfile;