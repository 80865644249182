import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clientUpdated } from '../../redux/client/clientSlice';
import Form from 'react-bootstrap/Form';

const NameControl = (props) => {
    const { client } = props;
    const dispatch = useDispatch();
    const editMode = useSelector(state => state.clients.editMode);

    return (
        <div className='tarot-name-control' data-testid='tarot-name-control'>
            {editMode?
            <Form.Control 
                type="text" 
                placeholder="Имя клиента"
                className="mb-3 tarot-client-name"
                value={client.name ? client.name : ""}
                onChange={(e) => dispatch(clientUpdated({ id: client?.id, name: e.target.value }))}
                autoFocus 
            />
            :
            <div className="mb-3 tarot-client-name">
                {client?.name ? client?.name : "Новый клиент"}
            </div>}
        </div>
    )
}

export default NameControl;