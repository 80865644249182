Path2D.prototype.roundRect = function (x, y, w, h, r) {
    if (w < 2 * r) r = w / 2;
    if (h < 2 * r) r = h / 2;
    this.moveTo(x+r, y);
    this.arcTo(x+w, y,   x+w, y+h, r);
    this.arcTo(x+w, y+h, x,   y+h, r);
    this.arcTo(x,   y+h, x,   y,   r);
    this.arcTo(x,   y,   x+w, y,   r);
    this.closePath();
    return this;
}

export const drawHintButton = (ctx, geometry) => {
    ctx.save();
    ctx.globalAlpha = 0.5;
    ctx.fillStyle = "#D4E8EA";
    ctx.fill(geometry.geo.hintBtn)
    ctx.strokeStyle = "#185c39";
    ctx.setLineDash([3, 3]);
    ctx.stroke(geometry.geo.hintBtn)
    ctx.setLineDash([]);
    ctx.fillStyle = "#185c39";
    ctx.beginPath();
    ctx.arc(geometry.geo.stage ? geometry.geo.stage.width - 30 : 70, 
        30, 15, 0, 2 * Math.PI);
    ctx.fill();
    ctx.fillStyle = "white";
    ctx.font = `bold 30px sans-serif`;
    ctx.textAlign="center"; 
    ctx.textBaseline = "middle";
    ctx.fillText('?', geometry.geo.stage ? geometry.geo.stage.width - 30 : 70, 32);
    ctx.restore();
}

export const drawPlusButton = (ctx, geometry) => {
    ctx.save();
    ctx.globalAlpha = 0.5;
    ctx.fillStyle = "#D4E8EA";
    ctx.fill(geometry.geo.plusBtn)
    ctx.strokeStyle = "#185c39";
    ctx.setLineDash([3, 3]);
    ctx.stroke(geometry.geo.plusBtn)
    ctx.setLineDash([]);
    ctx.fillStyle = "#185c39";
    ctx.beginPath();
    ctx.arc(geometry.geo.stage ? geometry.geo.stage.width - 30 : 70, 
        geometry.geo.stage ? geometry.geo.stage.height - 30 : 70, 15, 0, 2 * Math.PI);
    ctx.fill();
    ctx.fillStyle = "white";
    ctx.font = `bold 30px sans-serif`;
    ctx.textAlign="center"; 
    ctx.textBaseline = "middle";
    ctx.fillText('+', geometry.geo.stage ? geometry.geo.stage.width - 30 : 70, 
        geometry.geo.stage ? geometry.geo.stage.height - 30 : 70);
    ctx.restore();
}

export const drawPlusButtonExtra = (ctx, geometry) => {
    ctx.save();
    ctx.globalAlpha = 0.5;
    ctx.fillStyle = "#D4E8EA";
    ctx.fill(geometry.geo.plusBtn)
    ctx.strokeStyle = "#185c39";
    ctx.setLineDash([3, 3]);
    ctx.stroke(geometry.geo.plusBtn)
    ctx.setLineDash([]);
    ctx.fillStyle = "#185c39";
    ctx.beginPath();
    ctx.arc(geometry.geo.stage ? geometry.geo.stage.width / 2 : 50, 
        geometry.geo.stage ? geometry.geo.stage.height + 20 : 50, 15, 0, 2 * Math.PI);
    ctx.fill();
    ctx.fillStyle = "white";
    ctx.font = `bold 30px sans-serif`;
    ctx.textAlign="center"; 
    ctx.textBaseline = "middle";
    ctx.fillText('+', geometry.geo.stage ? geometry.geo.stage.width / 2 : 50, 
        geometry.geo.stage ? geometry.geo.stage.height + 20 : 50);
    ctx.restore();
}

export const drawTarget = (ctx, pos) => {
    ctx.save();
    ctx.globalAlpha = 0.5;
    ctx.fillStyle = "white";
    ctx.beginPath();
    ctx.arc(pos.path?.center.x, pos.path?.center.y, pos.width / 3, 0, 2 * Math.PI);
    ctx.fill();
    ctx.strokeStyle = "#185c39";
    ctx.beginPath();
    ctx.arc(pos.path?.center.x, pos.path?.center.y, pos.width / 3, 0, 2 * Math.PI);
    ctx.stroke();
    ctx.beginPath();
    ctx.arc(pos.path?.center.x, pos.path?.center.y, pos.width / 4, 0, 2 * Math.PI);
    ctx.stroke();
    ctx.beginPath();
    ctx.arc(pos.path?.center.x, pos.path?.center.y, pos.width / 6, 0, 2 * Math.PI);
    ctx.stroke();
    ctx.beginPath();
    ctx.arc(pos.path?.center.x, pos.path?.center.y, pos.width / 12, 0, 2 * Math.PI);
    ctx.stroke();
    ctx.beginPath();
    ctx.moveTo(pos.x - pos.width / 3, pos.y);
    ctx.lineTo(pos.x + pos.width / 3, pos.y);
    ctx.moveTo(pos.x, pos.y - pos.width / 3);
    ctx.lineTo(pos.x, pos.y + pos.width / 3);
    ctx.stroke();
    ctx.restore();
}