import React from 'react';
import { useSelector } from 'react-redux';
import ClientDeal from './ClientDeal';
import { AppError } from '../AppError';
import Spinner from 'react-bootstrap/Spinner';
import { selectDealsSortParams } from '../../redux/client/clientSlice';
import { useGetClientDealsQuery } from '../../api/dealApi';

const ClientDeals = (props) => {
    const { client } = props;
    const sort = useSelector(selectDealsSortParams);

    const { data: deals, isSuccess: isDealsSuccess, isLoading } = useGetClientDealsQuery({ clientId: client?.id, sort: sort }, 
        { skip: client === undefined || client?.id == 0 })

    return (
        <div data-testid="tarot-client-deal-list">
            {isLoading ?
            <div id="tarology-spinner" data-testid="tarology-spinner">
                <Spinner animation="border" />
            </div>
            :
            isDealsSuccess ?
            <>
            {deals.map((deal, index) => {
                return(
                    <AppError module="ClientDeal" key={index}>
                        <ClientDeal deal={deal} />
                    </AppError>
                );
            })}
            </>
            :
            <></>}
        </div>
    )
}

export default ClientDeals;