import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectProduct } from '../../redux/settings/settingsSlice';
import { useHistory } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Toast from 'react-bootstrap/Toast';
import { FaSave } from "react-icons/fa";
import { useAddCustomerMutation, useUpdateCustomerMutation } from '../../api/clientApi';

const ClientSaveButton = (props) => {
    const { client } = props;
    const [toast, setToast] = useState({});
    const [showToast, setShowToast] = useState(false);
    const [updateCustomer, { isUpdateLoading }] = useUpdateCustomerMutation()
    const [addCustomer, { isAddLoading }] = useAddCustomerMutation()
    const product = useSelector(selectProduct);
    const history = useHistory();

    const handleSave = async () => {
        if (client.id != 0) {
            try {
                await updateCustomer(client).unwrap()
            } catch {
              setToast({
                title: 'Не получилось...',
                description: "Не удалось сохранить клиента, пожалуйста попробуйте еще раз.",
                status: 'error'
              })
              setShowToast(true)
            }
        } else {
            try {
                const res = await addCustomer(client).unwrap()
                history.push(`/client/${res.id}`)
            } catch {
              setToast({
                title: 'Не получилось...',
                description: "Не удалось сохранить клиента, пожалуйста попробуйте еще раз.",
                status: 'error'
              })
              setShowToast(true)
            }
        }
      }

    return (
        <>
            {(isUpdateLoading || isAddLoading) ?
            <Button className="tarot-sex-button mr-3" size="sm" disabled={true} data-testid="tarot-save-client-button">
                <Spinner 
                    as="span"
                    variant="light"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    animation="border"
                    data-testid="tarot-save-client-spinner"
                /> Сохраняю...
            </Button>
            :
            <Button 
                className="tarot-sex-button mr-3" 
                size="sm" 
                onClick={handleSave} 
                disabled={product?.phase == "SUSPENDED" || product?.phase == "BLOCKED" || product?.name == "DEMO"}
                data-testid="tarot-save-client-button"
            >
                <FaSave/> Сохранить
            </Button>
            }
            <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide>
                <Toast.Header>
                    <strong className="me-auto">{toast?.title}</strong>
                </Toast.Header>
                <Toast.Body>{toast?.description}</Toast.Body>
            </Toast>
        </>
    )
}

export default ClientSaveButton;