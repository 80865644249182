export const dealIsEqualToSaved = (deal, geometry, currentDeal) => {
    if (deal != null) {
        if (deal?.card_refs?.length != geometry.ids.length) { return false }
        if (deal?.comments?.length != currentDeal?.comments?.length) { return false }
        if (deal.questions != currentDeal.questions) { return false }
        if (currentDeal.customer == 0) { return false }
        if (deal.customer != currentDeal.customer.id) { return false }

        let result = false;
        geometry.ids.map(id => {
            let card = geometry.entities[id];
            let tmpCard = deal.card_refs.find(rec => rec.position == card.position);
            if (!card.card?.id) { result = true }
            if (tmpCard?.card?.id != card.card?.id) { result = true }
            if (tmpCard?.inverted != card.inverted) { result = true }
            if (tmpCard?.extra_deal_type?.id != card?.extra_deal_type?.id) { result = true }
            if (tmpCard?.question != card?.question) { result = true }
            tmpCard?.extra_refs.map(extra => {
                if (extra.inverted != null) {
                    if (extra.inverted != card.extra.entities[extra.position].inverted) { result = true }
                } else {
                    if (card.extra.entities[extra.position].inverted) { result = true }
                }
                if (extra.selected != null) {
                    if (extra.selected != card.extra.entities[extra.position].selected) { result = true }
                } else {
                    if (card.extra.entities[extra.position].selected) { result = true }
                }
                if (extra.card?.id != null && card.extra.entities[extra.position].card?.id != null) {
                    if (extra.card?.id != card.extra.entities[extra.position].card?.id) { result = true }
                }
            })
        })
        if (result) { return false }

        result = false;
        currentDeal.comments.map(comment => {
            if (!deal.comments.find(rec => rec.text == comment.text)) { result = true }
        })
        if (result) { return false }

        let dateSaved = new Date(currentDeal.date)
        let dateCurrent = new Date(deal.date)
        if (dateSaved.toISOString() != dateCurrent.toISOString()) { return false }

        return true;
    } else {
        return false;
    }
}

export const isDealChangeEnabled = (geometry) => {
    let result = true;
    geometry.ids.map(id => {
        if (geometry.entities[id].card != null) { result = false }
    })    
    return result;
}    

export const isExportEnabled = (geometry, deal, currentDeal, /*client,*/ product) => {
    if (product?.phase == "SUSPENDED" || product?.phase == "BLOCKED" || product?.name == "DEMO") { return false }
    if (geometry.ids.length == 0) { return false }
    if (deal.id == 0) { return false }
    if (currentDeal?.customer == 0/* && !client*/) { return false }
    if (!dealIsEqualToSaved(deal, geometry, currentDeal)) { return false }

    return true;
}    

export const isSaveEnabled = (geometry, deal, client, currentDeal, product) => {
    if (product?.name == "DEMO" || (product?.phase != "ACTIVE" && product?.phase != "TRIAL")) { 
        return false 
    }
    if (isReadOnly(deal, product)) { return false }
    if (geometry.ids.length == 0) { return false }
    if (deal.id == 0 && !client) { return false }
    if (dealIsEqualToSaved(deal, geometry, currentDeal)) { return false }
    return true;
}    

export const isSaveEnabledWithAlert = (geometry) => {
    let result = { allowed: true, message: "" }

    geometry.ids.every(id => {
        if (geometry.entities[id] && !geometry.entities[id].card) {
            result.allowed = false
            result.message = "В раскладе есть незаполненные позиции, сохранение невозможно."
            return result
        }
    });

    return result
}

export const isReadOnly = (deal, product, system) => {
    if (product?.name == "DEMO" || (product?.phase != "ACTIVE" && product?.phase != "TRIAL")) { 
        return true;
    }
    if (deal?.deal_type?.title) {
        if (product?.ACCESS_DEAL_TYPE?.filter(type => type == deal.deal_type.title)?.length == 0) {
            return true;
        }
    } 
    if (system) {
        if(product?.ACCESS_DECK?.filter(sys => sys == system)?.length == 0) {
            return true;
        }
    }

    return false;
}
