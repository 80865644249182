import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  confirmDeleteVisible: false,
  alert: {
    visible: false,
    message: ""
  },
  login: {
    visible: false,
  },
  register: {
    visible: false,
  },
  forgotten: {
    visible: false,
  },
}

const modalSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    showConfirmDelete(state, action) {
      state.confirmDeleteVisible = true
    },
    hideConfirmDelete(state, action) {
      state.confirmDeleteVisible = false
    },
    showAlert(state, action) {
      state.alert.visible = true
      state.alert.message = action.payload
    },
    hideAlert(state, action) {
      state.alert.visible = false
      state.alert.message = ""
    },
    showLogin(state, action) {
      state.login.visible = true
    },
    hideLogin(state, action) {
      state.login.visible = false
    },
    showRegister(state, action) {
      state.register.visible = true
    },
    hideRegister(state, action) {
      state.register.visible = false
    },
    showForgotten(state, action) {
      state.forgotten.visible = true
    },
    hideForgotten(state, action) {
      state.forgotten.visible = false
    },
  },
})

export const { showConfirmDelete, hideConfirmDelete, showAlert,
  hideAlert, showLogin, hideLogin, showRegister, hideRegister,
  showForgotten, hideForgotten } = modalSlice.actions
  
export default modalSlice.reducer

export const getConfirmDeleteVisible = state => state.modals.confirmDeleteVisible;

export const getAlert = state => state.modals.alert;

export const getLogin = state => state.modals.login;

export const getRegister = state => state.modals.register;

export const getForgotten = state => state.modals.forgotten;