import { apiSlice } from '../redux/apiSlice'

const systemApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSystems: builder.query({
        query: () => `/systems`,
        providesTags: (result, error, id) => [{ type: 'Systems', id: 'LIST' }],
        keepUnusedDataFor: 36000
    }),
    getSystem: builder.query({
      query: systemId => `/systems/${systemId}`,
      providesTags: (result, error, id) => [{ type: 'Systems', id }],
      keepUnusedDataFor: 36000
    }),
    getSystemByName: builder.query({
      query: systemName => `/systems/name/${systemName}`,
      providesTags: (result, error, id) => [{ type: 'Systems', id }],
      keepUnusedDataFor: 36000
    }),
  }),
  overrideExisting: false,
})

export const { useGetSystemQuery, useGetSystemsQuery, useGetSystemByNameQuery } = systemApi