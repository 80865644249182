import { apiSlice } from '../redux/apiSlice'

const cardApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCards: builder.query({
      query: () => '/cards',
      providesTags: (result, error, id) => [{ type: 'Cards', id: 'LIST' }],
      keepUnusedDataFor: 36000
    }),
    getCard: builder.query({
      query: cardId => `/cards/${cardId}`,
      providesTags: (result, error, id) => [{ type: 'Cards', id }],
      keepUnusedDataFor: 36000
    }),
  }),
  overrideExisting: false,
})

export const { useGetCardsQuery, useGetCardQuery } = cardApi