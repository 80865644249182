import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typeahead } from 'react-bootstrap-typeahead';
import Spinner from 'react-bootstrap/esm/Spinner';
import { getActiveClientId, clientUpdated, clientDeleted, setActiveClientId, 
    getActiveClient, setActiveClient } from '../../redux/client/clientSlice';
import { getActiveDeal, setActiveDeal } from '../../redux/deals/dealsSlice';
import { useGetCustomersQuery } from '../../api/clientApi';

import 'react-bootstrap-typeahead/css/Typeahead.css';

const CustomerSelector = (props) => {
    const dispatch = useDispatch();
    const { data: clients, isSuccess: isClientsSuccess } = useGetCustomersQuery({ sort: "name:asc", paging: null })
    const activeClient = useSelector(getActiveClient);
    const deal = useSelector(getActiveDeal);

    const handleSetClient = (e) => {
        if (e !== undefined) {
            if (e.length == 0) {
                dispatch(clientDeleted(0));
                dispatch(setActiveDeal({...deal, customer: null }));
                dispatch(setActiveClientId(null));    
                dispatch(setActiveClient({}));    
            } else {
                let tmpClient = e[0]
                if (tmpClient.customOption == true) {
                    dispatch(clientUpdated({ name: tmpClient.name }));
                    dispatch(setActiveDeal({...deal, customer: 0 }));
                    dispatch(setActiveClientId(0));
                } else {
                    if (tmpClient?.id == 0) { dispatch(clientUpdated({ ...tmpClient, name: tmpClient.name })) }
                    dispatch(setActiveDeal({...deal, customer: tmpClient.id }));
                    dispatch(setActiveClientId(tmpClient.id))
                }
            }
        }
    }

    return (
        <div id="tarot-deal-client" data-testid="tarot-deal-client" style={{ maxHeight: "40px" }}>
            {isClientsSuccess ?
            <Typeahead
                id="basic-typeahead-single"
                newSelectionPrefix="Новый клиент: "
                labelKey="name"
                onChange={handleSetClient}
                options={clients.entities}
                placeholder="Клиент"
                allowNew={true}
                selected={Object.keys(activeClient).length > 0 ? [activeClient] : []}
                className="mb-3"
            />
            :
            <div id="tarology-spinner" data-testid="tarology-spinner">
                <Spinner animation="border" />
            </div>
            }
        </div>
    )
}

export default CustomerSelector;