import { apiSlice } from '../redux/apiSlice'

const dealApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDeals: builder.query({
      query: () => '/deals',
      providesTags: (result, error, id) => [{ type: 'Deals', id: 'LIST' }],
      keepUnusedDataFor: 36000
    }),
    getClientDeals: builder.query({
      query: ({clientId, sort}) => `/deals/customer/${clientId}?sort=${sort}`,
      providesTags: (result, error, id) => [{ type: 'Deals', id }],
      keepUnusedDataFor: 36000
    }),
    getDealsOfFavorites: builder.query({
      query: () => '/deals?favorites=true',
      providesTags: (result, error, id) => [{ type: 'Deals', id: 'FAVS' }],
      keepUnusedDataFor: 36000
    }),
    getDeal: builder.query({
      query: dealId => `/deals/${dealId}`,
      keepUnusedDataFor: 36000
    }),
    addDeal: builder.mutation({
      query(data) {
        const {id, dealTypeId, systemId, deal_type, customer, ...body} = data.deal;
        const geometry = { ...data.geometry }
        body.customer = { id: customer };
        body.deal_type = { id: dealTypeId };
        let card_refs = [];
        geometry.ids.map(id => {
          let tmpCardRef = {};
          let pos = geometry.entities[id];
          tmpCardRef.position = pos.position;
          tmpCardRef.inverted = pos.inverted;
          tmpCardRef.question = pos.question;
          tmpCardRef.card = { id: pos.card.id };
          if (pos.extra_deal_type && pos.extra_deal_type?.id) {
            tmpCardRef.extra_deal_type = { id: pos.extra_deal_type?.id };
          }
          let extraCards = [];
          pos.extra.ids.map(eid => {
            let newRef = {}
            newRef.card = { id: pos.extra.entities[eid].card.id }
            newRef.position = pos.extra.entities[eid].position
            newRef.inverted = pos.extra.entities[eid].inverted
            newRef.selected = pos.extra.entities[eid].selected
            extraCards.push(newRef)
          })
          tmpCardRef.extra_refs = extraCards;
          card_refs.push(tmpCardRef);
        })
        body.card_refs = card_refs;
        let comments = [];
        body.comments.map(comment => {
          let tmpComment = {};
            tmpComment.text = comment.text;
            comments.push(tmpComment);
        });
        body.comments = comments;
        console.log(body)
  
        return {
          url: `/deals`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: [{ type: 'Deals', id: 'FAVS' },
                        { type: 'Deals', id: 'LIST' }] 
    }),
    updateDeal: builder.mutation({
      query(data) {
        const {id, dealTypeId, systemId, deal_type, customer, ...body} = data.deal;
        const geometry = { ...data.geometry }
        body.customer = { id: customer };
        body.deal_type = { id: dealTypeId };
        let card_refs = [];
        geometry.ids.map(id => {
          let tmpCardRef = {};
          let pos = geometry.entities[id];
          tmpCardRef.position = pos.position;
          tmpCardRef.inverted = pos.inverted;
          tmpCardRef.question = pos.question;
          tmpCardRef.card = { id: pos.card.id };
          if (pos.extra_deal_type && pos.extra_deal_type?.id) {
            tmpCardRef.extra_deal_type = { id: pos.extra_deal_type?.id };
          } else {
            tmpCardRef.extra_deal_type = null
          }
          let extraCards = [];
          pos.extra.ids.map(eid => {
            let newRef = {}
            newRef.card = { id: pos.extra.entities[eid].card.id }
            newRef.position = pos.extra.entities[eid].position
            newRef.inverted = pos.extra.entities[eid].inverted
            newRef.selected = pos.extra.entities[eid].selected
            extraCards.push(newRef)
          })
          tmpCardRef.extra_refs = extraCards;
          card_refs.push(tmpCardRef);
        })

        body.card_refs = card_refs;
        let comments = [];
        body.comments.map(comment => {
            let tmpComment = {};
            tmpComment.text = comment.text;
            comments.push(tmpComment);
        });
        body.comments = comments;
  
        return {
          url: `/deals/${id}`,
          method: 'PUT',
          body
        }
      },
      invalidatesTags: (result, error, { id }) => [ { type: 'Deals', id }, 
                                                    { type: 'Deals', id: 'FAVS' },
                                                    { type: 'Deals', id: 'LIST' }],
    }),
    deleteDeal: builder.mutation({
      query(id) {
        return {
          url: `/deals/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: (result, error, id) => [ { type: 'Deals', id }, 
                                                { type: 'Deals', id: 'FAVS' },
                                                { type: 'Deals', id: 'LIST' }],
    }),
  }),
  overrideExisting: false,
})

export const { useGetClientDealsQuery, useGetDealsQuery, 
  useGetDealQuery, useGetDealsOfFavoritesQuery, useAddDealMutation,
  useUpdateDealMutation, useDeleteDealMutation } = dealApi
