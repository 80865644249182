import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { setAuthenticated } from '../redux/settings/settingsSlice';
import { useLoginMutation, useRerequestMutation } from '../api/authApi';

const Login = (props) => {
    const isAuthenticated = useSelector(state => state.settings.authenticated); 
    const dispatch = useDispatch();
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [error, setError] = useState(null)
    const history = useHistory();

    const [login, { isLoading }] = useLoginMutation();
    const [rerequest] = useRerequestMutation();

	useEffect(
		() => {
            let accessToken = localStorage.getItem("accessToken")
			if (accessToken) {
				dispatch(setAuthenticated(true))
            }
            if (isAuthenticated) {
                history.push(`/`)
            } 
		}, [isAuthenticated]
	)


    return (
        <>
        <div className="splash-body" style={{ background: `url("/image/tarology-bg.jpg")`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
        <div className='splash-card'>
                <div className='splash-logo'>
                    <Image src='/image/tarology.svg' fluid />
                </div>
                <p className='tarot-intro-title'>Войдите в вашу учетную запись</p>
                <Row className="tarot-intro-row">
                <Col>
                    <p className='tarot-modal-p'><small>Имя пользователя</small></p>
                    <input 
                        type="text" 
                        value={username}
                        className="tarot-modal-input"
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </Col>
            </Row>
            <Row className="tarot-intro-row">
                <Col>
                    <p className='tarot-modal-p'><small>Пароль</small></p>
                    <input 
                        type="password" 
                        value={password}
                        className="tarot-modal-input"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </Col>
            </Row>
            <Row className="tarot-intro-row">
                <Col>
                    <p className='tarot-modal-p-sub'>
                        <small><input type="checkbox" /> Запомнить меня</small>
                    </p>
                </Col>
                <Col style={{ textAlign: "right" }}>
                    <p className='tarot-modal-p-sub'>
                        <small>
                            <span className="tarot-intro-link"
                                onClick={async () => { history.push(`/reminder`) }}>
                                Забыли пароль?
                            </span>
                        </small>
                    </p>
                </Col>
            </Row>
            {error &&
            <Row id="subscribe-confirm-body">
                <Col>
                    <center>
                        <p className='tarot-modal-p-error'>
                            <small>{error}</small>
                        </p>
                    </center>
                </Col>
            </Row>}
            <Button className="tarot-intro-button tarot-sex-button" onClick={async () => {
                    try {
                        await login({ username: username, password: password }).unwrap()
                        setError(null)
                        history.push(`/`)
                    } catch (err) {
                        switch (err.status) {
                            case 401:
                                setError("Имя пользователя или пароль введены неверно")
                                break;
                            case 409:
                                setError(<>Аккаунт еще не активирован. Пожалуйста найдите в почтовом ящике 
                                    письмо от Тарологии и 
                                    <span className="tarot-intro-link" onClick={async () => {
                                        try {
                                            await rerequest({ username: username }).unwrap()
                                            setError("Новое письмо выслано!")
                                        } catch (err) {
                                            setError("Что-то пошло не так...")
                                        }
                                    }}> пройдите 
                                    по ссылке </span> или запросите новое письмо.</>)
                                break;
                            default:
                                setError("Что-то пошло не так...")
                        }
                    }
                }} 
                data-testid="modal-button-ok">
                Вход
            </Button>
            <p className='tarot-intro-footer'>Новый пользователь? &nbsp;
                <span className='modal-registration-link' 
                    onClick={() => { history.push(`/register`) }}
                >Регистрация</span>
            </p>
            </div>
        </div>
        </>
    )
}

export default Login;