import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
import { getActiveDeal, setActiveDeal } from '../../redux/deals/dealsSlice';

const QuestionArea = (props) => {
    const dispatch = useDispatch();
    const deal = useSelector(getActiveDeal);

    return (
        <Form.Control 
            as="textarea" 
            rows={5} 
            className="tarot-text" 
            value={deal?.questions || ""}
            onChange={(e) => dispatch(setActiveDeal({...deal, questions: e.target.value}))}
            placeholder="Вопросы и задача" 
        />
    )
}

export default QuestionArea;