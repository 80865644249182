import React from 'react';
import axios from 'axios';
import prepareHeaders from '../helpers/prepare-headers';

const ErrorComponent = () => {
  return <span className='error-message'>Что-то пошло не так... Но мы обязательно разберемся.</span>;
};

const { TAROT_API_URL } = process.env;

export class AppError extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            module: props.module
        };
    }
  
    static getDerivedStateFromError = error => {
        return { hasError: true };
    };

    componentDidCatch = async (error, info) => {
        this.setState({ error, info });
        await axios.post(
            `${TAROT_API_URL}/log`, 
            { 
                application: "tarology", 
                module: this.state.module, 
                message: error.message,
                stack: info.componentStack
            }, {
                headers: prepareHeaders(localStorage.getItem('accessToken'))
            });
    };

    render() {
        const { hasError } = this.state;
        const { children } = this.props;

        return hasError ? <ErrorComponent /> : children;
    }
}