import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fillPosition, increaseLimit, selectLimit, addQuestion,
    fillExtraGeometry, chooseExtraCard, selectExtraGeometry,
    invertPosition } from '../redux/geometry/geometrySlice';
import Form from 'react-bootstrap/Form';
import * as geo from '../helpers/geometry';
import * as drawing from '../helpers/draw';
import ModalChooseCard from './ModalChooseCard';
import Canvas from './Canvas';
import loadImages from '../helpers/loadImages';
import { useGetExtraDealTypeQuery } from '../api/extraDealTypeApi';
import { useGetCardsQuery } from '../api/cardApi';

const AdditionalDeal = ({ typeId, system, position, question, readOnly, user, card, tableRef }) => {
    const [singleSelections, setSingleSelections] = useState([]);
	const [visible, setVisible] = useState(false);
	const [theCard, setTheCard] = useState(null);
    const [cardHover, setCardHover] = useState("");

    const dispatch = useDispatch();
    const { data: cards, isSuccess: isCardsSuccess } = useGetCardsQuery();
    const geometry = useSelector((state) => selectExtraGeometry(state, position));
    const limit = useSelector(state => selectLimit(state, position));
    const { data: type, isSuccess: isExtraTypeSuccess } = useGetExtraDealTypeQuery(typeId)

    useEffect(
        () => {
            if (type) {
                if (limit == 0) {
                    let tmpLimit = adjustLimit()
                    let tmpGeometry = geo.calculatePositions(type.deal_cards.slice(0, tmpLimit), 
                    tableRef.current.offsetWidth / 4, tableRef.current.offsetWidth / 4, true, true);
                    dispatch(fillExtraGeometry({ geometry: tmpGeometry, selectable: type.selectable, 
                        fixed: type?.fixed, card: card, position: position || [] }));
                } else {
                    let tmpGeometry = geo.calculatePositions(type.deal_cards.slice(0, limit), 
                    tableRef.current.offsetWidth / 4, tableRef.current.offsetWidth / 4, true, true);
                    dispatch(fillExtraGeometry({ geometry: tmpGeometry, selectable: type.selectable,
                        fixed: type?.fixed, card: card, position: position || [] }));
                }
            }
        }, [type, limit]
    )

    const adjustLimit = () => {
        let tmpLimit;
        if (type?.initialLimit != null && type?.initialLimit > 0) {
            if (geometry.ids.length > type.initialLimit) {
                dispatch(increaseLimit({extra: position, newVal: geometry.ids.length}))
                tmpLimit = geometry.ids.length;
            } else {
                dispatch(increaseLimit({extra: position, newVal: type.initialLimit}))
                tmpLimit = type.initialLimit;
            }
        } else {
            dispatch(increaseLimit({extra: position, newVal: type.deal_cards.length}))
            tmpLimit = type.deal_cards.length;
        }
        return tmpLimit
    }

    useEffect(
		() => {
            if (singleSelections.length > 0) {
                let card = cards.find(rec => rec.title == singleSelections
                    && rec.system == system?.title);
                dispatch(fillPosition({ card: card, position: theCard, extra: position }));
                setSingleSelections([]);
            }
			setVisible(false);	
		}, [singleSelections]
	);

    const handleLimitIncrease = () => {
        let numCards = type.deal_cards.length;
        if (limit < numCards) {
            dispatch(increaseLimit({extra: position}))
        }
    }

    const getTargetId = (event, ref) => {
        const canvas = ref.current;
        const context = canvas.getContext('2d');
        let rect = canvas.getBoundingClientRect();
        let x = event.clientX - rect.left;
        let y = event.clientY - rect.top;
        let targetId;
        geometry.ids.map(id => {
            if (context.isPointInPath(geometry.entities[id].path, x, y)) {
                targetId = id;
            }   
        })

        return targetId;
    }

    const handleCanvasClick = (event, ref) => { 
        if (!readOnly || (user?.status == "unpaid" || user?.role?.name == "Demo")) {
            const canvas = ref.current
            const context = canvas.getContext('2d')
            let rect = canvas.getBoundingClientRect();
            let x = event.clientX - rect.left;
            let y = event.clientY - rect.top;
            let targetId = getTargetId(event, ref);
            if (targetId) {
                let target = new Path2D();
                let pos = geometry.entities[targetId];
                target.arc(pos.path?.center.x, pos.path?.center.y, pos.width / 3, 0, 2 * Math.PI);
                target.closePath();
                if (cardHover == pos.id && pos.card && context.isPointInPath(target, x, y) && type.selectable) {
                    dispatch(chooseExtraCard({ position: targetId, extra: position }));
                } else {
                    setTheCard(targetId);
                    if (type?.fixed != targetId) {
                        setVisible(true);
                    }
                }
            } else if (context.isPointInPath(geometry.geo.plusBtn, x, y)) {
                handleLimitIncrease();
            }
        }
    };

    const handleCanvasContext = (event, ref) => { 
        if (!readOnly || (user?.status == "unpaid" || user?.role?.name == "Demo")) {
            let targetId = getTargetId(event, ref);
            if (targetId) {
                dispatch(invertPosition({ position: targetId, extra: position }));
            }
        }
    };

    const handleCanvasMouseMove = (event, ref) => {
        if (!readOnly || (user?.status == "unpaid" || user?.role?.name == "Demo")) {
            let targetId = getTargetId(event, ref);
            if (targetId) {
                setCardHover(targetId);
            }
        }
    }

    const draw = (ctx) => {
        loadImages(geometry, function(images) {
            ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
            ctx.strokeStyle = "black";
            let stage = geo.adjustStageDimensions(geometry.geo.stage)
            ctx.canvas.width = stage.width
            ctx.canvas.height = stage.height
            if (type?.initialLimit != null) {
                ctx.canvas.height = ctx.canvas.height + 70;
            }

            geometry.ids.map((id, index) => {
                let pos = geometry.entities[id];
                
                ctx.save();
                ctx.shadowOffsetX = 5;
                ctx.shadowOffsetY = 5;
                ctx.shadowBlur = 5;
                ctx.shadowColor = 'rgba(0, 0, 0, 0.5)';
                ctx.fillStyle = "#D4E8EA";
                ctx.fill(pos.path)
                ctx.restore();

                if (pos && pos.card) {
                    ctx.save();
                    ctx.clip(pos.path)
                    ctx.translate(pos.x, pos.y)
                    ctx.rotate(pos.rotation * Math.PI / 180);
                    ctx.translate(-pos.x, -pos.y)
                    ctx.drawImage(images[id], pos.x - pos.offsetX, pos.y - pos.offsetY, pos.width, pos.height);
                    ctx.restore();
                }                   

                ctx.strokeStyle = "black";
                ctx.lineWidth = 2;

                if (!pos || !pos.card) {
                    ctx.save()
                    ctx.translate(pos.x, pos.y)
                    ctx.rotate(pos.rotation * Math.PI / 180);
                    ctx.translate(-pos.x, -pos.y)
                    ctx.fillStyle = "white";
                    ctx.font = `bold ${pos.width / 2}px sans-serif`;
                    ctx.textAlign="center"; 
                    ctx.textBaseline = "middle";
                    ctx.fillText(pos.position, pos.path?.center.x, pos.path?.center.y);
                    ctx.restore();
                }

                ctx.stroke(pos.path)

                if (type?.initialLimit && type?.initialLimit != 0 &&
                    limit < type.deal_cards.length) {
                    drawing.drawPlusButtonExtra(ctx, geometry);
                }

                if (cardHover == pos.id && pos.card && type.selectable) {
                    drawing.drawTarget(ctx, pos)
                }

                if (pos.selected == false && type.selectable) {
                    ctx.save();
                    ctx.globalAlpha = 0.5;
                    ctx.fillStyle = "white";
                    ctx.fill(pos.path);
                    ctx.restore();
                }
            })
        })
    }

    return (
        <div className='mt-3' id="work-area-extra">
        {type?.initialLimit != null &&
            <Form.Control as="textarea" rows={5} className="tarot-text" 
                style={{ fontSize: "0.8rem" }}
                value={question || ""}
                onChange={(e) => dispatch(addQuestion({ position: position, question: e.target.value }))}
                placeholder="Вопросы к докладу" 
                readOnly={readOnly}/>}

        {geometry.geo &&
            <>
                <Canvas draw={draw} onClick={handleCanvasClick} onContextMenu={handleCanvasContext} 
                    onMouseMove={handleCanvasMouseMove} onMouseLeave={() => setCardHover("")} />
            </>
        }

        {cards && system &&
        <ModalChooseCard visible={visible} cards={cards.filter(rec => rec.system == system?.title).map(rec => rec.title)}
            close={() => setVisible(false)} handleChange={setSingleSelections} selected={singleSelections}/>
        }

        </div>
    )
}

export default AdditionalDeal;