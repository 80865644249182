const parseLocation = (loc) => {       
    const queryString = require('query-string');

    const query = queryString.parse(loc.pathname);
    let parsed = Object.keys(query)[0].split("/");
    let result = { location: parsed[1], id: parsed[2] }
    
    return result;
}

export default parseLocation;