import React, { useState, useEffect, useContext, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from "react-router-dom";
import { setActiveClientId, setActiveClient } from '../redux/client/clientSlice';
import { getActiveDeal, setActiveDeal, getZeroDeal } from '../redux/deals/dealsSlice';
import { fillGeometry, resetGeometry, fillPositions, fillPosition, increaseLimit, 
    resetLimit, selectLimit, selectGeometry, addExtraDeal, 
    invertPosition, removeExtraDeal } from '../redux/geometry/geometrySlice';
// import { getKeycloak } from '../redux/settings/settingsSlice';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ImageB from 'react-bootstrap/Image';
import Table from 'react-bootstrap/Table'
import useWindowDimensions from '../useWindowDimensions';
import ReactMarkdown from 'react-markdown'
import * as geo from '../helpers/geometry';
import * as drawing from '../helpers/draw';
import { ExpandedContext } from '../expanded-context';
import { blockquoteRenderer } from '../helpers/blockquote-renderer';
import ModalChooseCard from './ModalChooseCard';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import Spinner from 'react-bootstrap/Spinner';
import Canvas from './Canvas';
import loadImages from '../helpers/loadImages';
import ControlPanel from './control-panel/ControlPanel';
import CommentsSection from './CommentsSection';
import AdditionalDeal from './AdditionalDeal';
import ExraDealArticles from './ExraDealArticles';
import { AppError } from './AppError';
import Floating from './Floating';
import Article from './Article';
import { isReadOnly } from '../helpers/deal-state-check';
import { useGetSelfAccountQuery } from '../api/accountApi';
import { useGetDealQuery } from '../api/dealApi';
import { useGetSystemsQuery, useGetSystemByNameQuery } from '../api/systemApi';
import { useGetCardsQuery } from '../api/cardApi';

import '../scss/custom.scss';
import '../css/nkp.css';
import '../css/tile.css';
import "../css/contextmenu.css";

const Deal = (props) => {
    const [expanded, setExpanded] = useContext(ExpandedContext);
	const [visible, setVisible] = useState(false);
	const [theCard, setTheCard] = useState(null);
    const [singleSelections, setSingleSelections] = useState([]);
    const [hintVisible, setHintVisible] = useState(false);
    const history = useHistory();

    const tableRef = useRef(null);

    const { height, width } = useWindowDimensions();

	const { TAROT_API_URL } = process.env;

    const { dealId } = useParams();
    const dispatch = useDispatch();
    const deal = useSelector(getActiveDeal);
    const { data: system, isSuccess: isSystemSuccess } = useGetSystemByNameQuery(deal?.system, { skip: !deal?.system || deal?.systemId == 0 })
    const { data: systems, isSuccess: isSystemsSuccess } = useGetSystemsQuery();
    const { data: cards, isSuccess: isCardsSuccess } = useGetCardsQuery();
    const { data: existingDeal, isFetching, isSuccess } = useGetDealQuery(dealId, { skip: !dealId || dealId == 0 })
    const zeroDeal = useSelector(getZeroDeal);

    // const authData = useSelector(getKeycloak);
    const newClientStatus = useSelector(state => state.clients.newclient);
    const newDealStatus = useSelector(state => state.deals.newdeal);
    const newDealFlag = useSelector(state => state.deals.newDealAction);
    const geometry = useSelector(selectGeometry);
    const limit = useSelector(selectLimit);
    const { data: account, isSuccess: isAccountSuccess } = useGetSelfAccountQuery()

    useEffect(
        () => {
            if (newDealFlag) {
                resetDeal();
                dispatch({ type: "API_DEAL_FLAG_REMOVE" });
            }
        }, [newDealFlag]
    )

    useEffect(
        () => {
            if (newDealStatus?.id) {
                history.push(`/deal/${newDealStatus.id}`)
            }            
        }, [newDealStatus]
    );

    useEffect(
        () =>{
            if (isAccountSuccess) {
                if (account?.product?.name == "DEMO" || (account?.product?.phase != "ACTIVE" && account?.product?.phase != "TRIAL")) {
                    history.push(`/deal/0`);                
                }
                let currentDeal = {...existingDeal};
                if (dealId == 0) {
                    currentDeal = {...zeroDeal, id: 0}
                } 
                if (currentDeal && currentDeal.card_refs) {
                    resetDeal();

                    currentDeal.customer = currentDeal.customer?.id;
                    currentDeal.dealTypeId = currentDeal.deal_type?.id;
                    dispatch(setActiveClientId(currentDeal.customer_id))
                    let currentSystem;
                    if (currentDeal?.system) {
                        currentSystem = currentDeal.system;
                    } else if (currentDeal?.card_refs[0]?.card?.system) { 
                        currentSystem = currentDeal?.card_refs[0].card.system;
                    }
                    if (currentSystem) {
                        dispatch(setActiveDeal({...currentDeal, systemId: currentSystem.id }));
                    }
                    dispatch(increaseLimit({newVal: currentDeal.card_refs.length}))
                } else {
                    resetDeal()
                }
            }
        }, [dealId, existingDeal, zeroDeal, account?.product]
    )

    const resetDeal = () => {
        dispatch(setActiveClientId(null));
        let tmpDeal = {};
        tmpDeal.card_refs = [];
        tmpDeal.comments = [];
        tmpDeal.customer = 0;
        tmpDeal.systemId = null;
        tmpDeal.system = null;
        tmpDeal.dealTypeId = null
        let tmpDate = new Date();
        tmpDeal.date = tmpDate.toJSON();
        tmpDeal.deal_type = 0;
        tmpDeal.id = 0;
        tmpDeal.questions = "";
        dispatch(setActiveDeal(tmpDeal));
        dispatch(setActiveClient({}));
        dispatch(resetGeometry());
        dispatch(resetLimit());
    }

    useEffect(
        () => {
            if (singleSelections.length > 0) {
                let card = cards.find(rec => rec.title == singleSelections 
                    && rec.system == system?.title);
                dispatch(fillPosition({ card: card, position: theCard }));
                setSingleSelections([]);
            }
            setVisible(false);	
        }, [singleSelections]
    );
        
    // useEffect(
    //     () => {
    //         if (newClientStatus.id) {
    //             let newDeal = { ...deal, customer: newClientStatus.id };
    //             console.log("Dispatch setActiveDeal on  newClientStatus", newDeal)
    //             dispatch(setActiveDeal(newDeal));
    //             dispatch(saveDeal({ currentDeal: newDeal, authData: authData }));
    //         } 
    //     }, [newClientStatus]
    // );
       
    const handleLimitIncrease = () => {
        let numCards = deal?.dealType.deal_cards.length;
        if (limit < numCards) {
            dispatch(increaseLimit())
        }
    }

    const handleContextMenu = (e, data) => {
        dispatch(addExtraDeal({ pos: data.pos, type: data.type }));
    }

    const handleRemoveExtraDeal = (e, data) => {
        dispatch(removeExtraDeal({ pos: data.pos }));
    }

    const switchHintVisible = (value) => {
        if (value) {
            setHintVisible(value);
        } else {
            setHintVisible(!hintVisible);
        }
    }

    const refresh = (keepCards) => {
        const dealType = deal?.deal_type;
        let tmpLimit;
        if (dealType) {
            if (limit == 0) {
                if (dealType?.initialLimit != null && dealType?.initialLimit > 0) {
                    tmpLimit = dealType.initialLimit;
                } else {
                    tmpLimit = dealType.deal_cards.length;
                }
                dispatch(increaseLimit({newVal: tmpLimit}))
                let tmpGeometry = geo.calculatePositions(dealType.deal_cards.slice(0, tmpLimit), 
                    width, height, expanded);
                dispatch(fillGeometry({ geometry: tmpGeometry, keepCards: keepCards }));

                if (deal.id != 0) {
                    dispatch(fillPositions(deal.card_refs));
                }
            } else {
                let tmpGeometry = geo.calculatePositions(dealType.deal_cards.slice(0, limit), 
                width, height, expanded);
                dispatch(fillGeometry({ geometry: tmpGeometry, keepCards: keepCards }));

                if (deal.id != 0) {
                    dispatch(fillPositions(deal.card_refs));
                }
            }
        }
    }
    
    useEffect(
        () => {
            dispatch(resetLimit());
            refresh(false);
        }, [deal?.deal_type]
    )

    useEffect(
        () => {
            refresh(false);
        }, [deal?.card_refs, deal?.id]
    )

    useEffect(
        () => {
            refresh(true);
        }, [width, expanded, limit]
    )

    const handleCanvasClick = (event, ref) => { 
        if (!isReadOnly(deal, account?.product, system?.title)) {
            const canvas = ref.current
            const context = canvas.getContext('2d')
            let rect = canvas.getBoundingClientRect();
            let x = event.clientX - rect.left;
            let y = event.clientY - rect.top;
            let targetId;
            geometry.ids.map(id => {
                if (context.isPointInPath(geometry.entities[id].path, x, y)) {
                    targetId = id;
                }   
            })
            if (targetId) {
                setTheCard(targetId);
                setVisible(true);
            } else if (context.isPointInPath(geometry.geo.hintBtn, x, y) && deal?.dealType?.hint != "") {
                setHintVisible(!hintVisible); 
            } else if (context.isPointInPath(geometry.geo.plusBtn, x, y)) {
                handleLimitIncrease();
            }
        }
    };

    const handleCanvasContext = (event, ref) => { 
        if (!isReadOnly(deal, account?.product, system?.title)) {
            const canvas = ref.current
            const context = canvas.getContext('2d')
            let rect = canvas.getBoundingClientRect();
            let targetId;
            geometry.ids.map(id => {
                let x = event.clientX - rect.left;
                let y = event.clientY - rect.top;
                if (geometry.entities[id].card && context.isPointInPath(geometry.entities[id].path, x, y)) {
                    targetId = id;
                }   
            })
            if (targetId) {
                dispatch(invertPosition({ position: targetId, extra: null }));
            }
        }
    };

    const draw = (ctx) => {
        if (newDealFlag) {
            ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)    
        } else {
            loadImages(geometry, function(images) {
                ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
                ctx.strokeStyle = "black";
                let stage = geo.adjustStageDimensions(geometry.geo.stage)
                ctx.canvas.width = stage.width
                ctx.canvas.height = stage.height

                geometry.ids.map((id, index) => {
                    let pos = geometry.entities[id];
                    
                    ctx.save();
                    ctx.shadowOffsetX = 5;
                    ctx.shadowOffsetY = 5;
                    ctx.shadowBlur = 5;
                    ctx.shadowColor = 'rgba(0, 0, 0, 0.5)';
                    ctx.fillStyle = "#D4E8EA";
                    ctx.fill(pos.path)
                    ctx.restore();

                    if (pos?.card) {
                        ctx.save();
                        ctx.clip(pos.path)
                        ctx.translate(pos.x, pos.y)
                        ctx.rotate(pos.rotation * Math.PI / 180);
                        ctx.translate(-pos.x, -pos.y)
                        ctx.drawImage(images[id], pos.x - pos.offsetX, pos.y - pos.offsetY, pos.width, pos.height);
                        ctx.restore();
                    }                   

                    ctx.strokeStyle = "black";
                    ctx.lineWidth = 2;

                    if (!pos || !pos.card) {
                        ctx.save()
                        ctx.translate(pos.x, pos.y)
                        ctx.rotate(pos.rotation * Math.PI / 180);
                        ctx.translate(-pos.x, -pos.y)
                        ctx.fillStyle = "white";
                        ctx.font = `bold ${pos.width / 2}px sans-serif`;
                        ctx.textAlign="center"; 
                        ctx.textBaseline = "middle";
                        ctx.fillText(pos.position, pos.path.center.x, pos.path.center.y);
                        ctx.restore();
                    }

                    ctx.stroke(pos.path)

                    if (deal?.deal_type?.hint != "" && deal?.deal_type?.hint != null) {
                        drawing.drawHintButton(ctx, geometry);
                    }

                    if (deal?.deal_type?.initialLimit && deal?.deal_type?.initialLimit != 0 &&
                        limit < deal?.deal_type.deal_cards.length) {
                        drawing.drawPlusButton(ctx, geometry);
                    }
                })
            })
        }
    }

    const calculateMinWidth = () => {
        let minWidth
        let delta = 0
        if (expanded) {
            delta = 240
        }

        if (width > 640 + delta) {
            minWidth = width - 440 - delta
        } else {
            minWidth = 200
        }

        return minWidth
    }

    return (
		<>
        <div className={expanded ? "tarot-content-expanded" : "tarot-content"}>
        {(!isAccountSuccess || systems == [] || systems == null /*|| dealTypes == [] || dealTypes == null*/) ?
            <div id="tarology-spinner">
                <Spinner animation="border" />
            </div>
            :
            <div className="content mt-3">
                <Row className="tarot-deal-container" style={{ flexWrap: 'nowrap' }} >
                    <Col className="mt-4 tarot-deal-stage">
                        <div className="p-3" id="work-area" style={{ 
                                minWidth: calculateMinWidth() }}>
                            <Canvas 
                                draw={draw} 
                                flag={newDealFlag.toString()} 
                                onClick={handleCanvasClick} 
                                onContextMenu={handleCanvasContext} 
                                data-testid="tx-deal-canvas"
                            />
                        </div>
                    </Col>
                    <Col className="mt-4 mr-3 tarot-deal-meta">
                        <AppError module="ControlPanel">
                            <ControlPanel dealId={dealId} system={deal?.systemId ? system : null} 
                                deal={deal} product={account?.product}
                                readOnly={isReadOnly(deal, account?.product, system?.title)} />
                        </AppError>
                    </Col>
                </Row>
                <Row>
                    <AppError module="CommentsSection">
                        <CommentsSection product={account?.product} system={system?.title}/>
                    </AppError>
                </Row>
                <Row className="mt-3 tarot-deal-explain">
                    <Table striped bordered hover ref={tableRef} id="tarot-meaning" data-testid="tarot-meaning">
                    <thead>
                        <tr>
                            <th width="15">Поз.</th>
                            <th className="w-25">Карта</th>
                            <th>Описание</th>
                        </tr>
                    </thead>
                    <tbody>
                        {geometry.ids && geometry.ids.map((pos, index) => {
                            let position = geometry.entities[pos]
                            let card = position?.card;
                            if (card) {
                                return (
                                    <tr key={index}>
                                        <td>{pos}</td>
                                        <td className="w-25" data-testid="tarot-meaning-card-name">
                                            <div>
                                                <strong>{card.title}</strong>
                                            </div>
                                            {position.inverted &&
                                            <div className="text-danger">
                                                <strong><em>(Перевернутая)</em></strong>
                                            </div>}
                                            <div>
                                                <ContextMenuTrigger id={"additional_cards_" + index} holdToDisplay={500} 
                                                    disable={isReadOnly(deal, account?.product, system?.title)}>
                                                    <ImageB src={`${TAROT_API_URL}/medias/${card.pic.url}`} data-testid="tarot-meaning-card-pic"
                                                        onContextMenu={e => e.preventDefault()} fluid />
                                                </ContextMenuTrigger>
                                                {deal?.deal_type?.extra_deal_types?.length > 0 &&
                                                    <ContextMenu id={"additional_cards_" + index}>
                                                        {deal?.deal_type?.extra_deal_types?.map((type, index) => {
                                                            return(
                                                                <MenuItem key={index} data={{type: type, pos: pos}} 
                                                                    disabled={type.id == position.extra_deal_type?.id ? true : false} 
                                                                    onClick={handleContextMenu}>
                                                                    {type.title}
                                                                </MenuItem>
                                                            )
                                                        })}
                                                        <MenuItem divider />
                                                        <MenuItem data={{pos: pos}} onClick={handleRemoveExtraDeal}>
                                                            Удалить доклад
                                                        </MenuItem>
                                                    </ContextMenu>
                                                }
                                                {position.extra_deal_type &&
                                                <>
                                                <AdditionalDeal 
                                                    type={position.extra_deal_type.id} 
                                                    tableRef={tableRef}
                                                    system={system} position={pos} question={position.question} 
                                                    readOnly={isReadOnly(deal, account?.product, system?.title)} card={card}
                                                    account={account} 
                                                    typeId={position.extra_deal_type.id} />
                                                </>
                                                }
                                            </div>
                                        </td>
                                        <td>
                                            <AppError module="Article">
                                                <Article cardId={card?.id} />
                                            </AppError>
                                            <AppError module="ExtraDealArticles">
                                                <ExraDealArticles position={pos} fixed={position.extra_deal_type?.fixed}/> 
                                            </AppError>
                                        </td>
                                    </tr>		
                                );
                            }
                        })}
                    </tbody>
                    </Table>
                </Row>
            </div>
        }
        </div>

        {hintVisible && 
            <Floating close={() => switchHintVisible(false)} title="Подсказка">
                <ReactMarkdown id="tarot-hint-content" components={{ 
                    h2: 'h3', 
                    blockquote: ({node}) => { return(blockquoteRenderer(node)) }
                }}
                children={dealType?.hint} />
            </Floating>
        }

        {cards && system &&
        <ModalChooseCard 
            visible={visible} 
            cards={cards.filter(rec => rec.system == system?.title).map(rec => rec.title)}
            close={() => setVisible(false)} 
            handleChange={setSingleSelections} 
            selected={singleSelections}
            data-testid="tx-deal-choose-card-modal"
        />
        }
		</>
	);
}

export default Deal;