import React, { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ExpandedContext } from '../expanded-context';
import { pageSizeSet, selectAllSorts, sortClientAdded, 
    sortClientUpdated, sortClientRemoved, getPDFFromApi, 
    selectSortParams, selectPaging } from '../redux/client/clientSlice';
import { FaFilePdf, FaStar, FaMars, FaVenus, FaSort,
    FaSortUp, FaSortDown } from "react-icons/fa";
import { Link } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Pages from './Pages';
import { getKeycloak } from '../redux/settings/settingsSlice';
import { useGetCustomersQuery } from '../api/clientApi';

const Clients = (props) => {
    const [expanded] = useContext(ExpandedContext);

    const dispatch = useDispatch();
    const pageSize = useSelector(state => state.clients.paging.size) || 10;
    const sorts = useSelector(selectAllSorts)
    const exportStatus = useSelector(state => state.clients.downloadStatus);
    const authData = useSelector(getKeycloak);
    const sort = useSelector(selectSortParams);
    const paging = useSelector(selectPaging);

    const { data: customers, isSuccess: isCustomersSuccess } = useGetCustomersQuery({sort, paging})

    const handleSetPageSize = (e) => {
        dispatch(pageSizeSet(e.target.value))
    }

    const SexIcon = (props) => {
        switch(props.sex) {
            case "male":
                return <FaMars />
            case "female":
                return <FaVenus />
            default:
                return ""
        }
    }

    const handleChangeSort = (field) => {
        let current = sorts.find(rec => rec.field === field)
        if (current.order == "asc") {
            dispatch(sortClientUpdated({ field: field, order: 'desc' }));
        } else if (current.order == "desc") {
            dispatch(sortClientRemoved({ field: field }));
        } else {
            dispatch(sortClientAdded({ field: field, order: 'asc' }));   
        }
    }

    const handleDownload = () => {
        if (exportStatus == 'idle') {
            dispatch(getPDFFromApi({ authData: authData }))
        }
    }

    return (
        <div className={expanded ? "tarot-content-expanded" : "tarot-content"}>
            {isCustomersSuccess &&
            <div className="content mt-3 mr-5">
                <div id="buttons">
                {exportStatus == 'active' ?
                    <Button className="tarot-sex-button mr-3 mt-3 mb-3" disabled={true} size="sm">
                        <Spinner 
                            as="span"
                            variant="light"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            animation="border"
                            data-testid="tarot-download-clients-spinner"
                        /> Скачиваю...
                    </Button>
                    :
                    <Button 
                        className="tarot-sex-button mr-3 mt-3 mb-3" 
                        size="sm" 
                        data-testid="tarot-download-button"
                        onClick={handleDownload}
                    >
                        <FaFilePdf/> Выгрузить в формате PDF
                    </Button>}
                </div>
                <Table striped>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th className="client-table-header" data-testid="client-thead-sort" 
                                onClick={() => handleChangeSort("name")}>
                                <span>Имя</span>
                                {sorts?.find(rec => rec.field === "name")?.order == "asc" ?
                                <FaSortUp /> : 
                                sorts?.find(rec => rec.field === "name")?.order == "desc" ?
                                <FaSortDown /> : <FaSort />}
                            </th>
                            <th>Пол</th>
                            <th>VIP</th>
                            <th>E-mail</th>
                            <th>Телефон</th>
                            <th>Расклады</th>
                        </tr>
                    </thead>
                    <tbody>
                    {customers.entities.map((client, index) => {
                        return(
                        <tr key={index} data-testid="tarot-client-trow">
                            <td>{index + 1}</td>
                            <td data-testid="tarot-client-tname">
                                <Link to={`/client/${client.id}`}>
                                    {client.name}
                                </Link>
                            </td>
                            <td data-testid="tarot-client-tsex"><center><SexIcon sex={client.sex} /></center></td>
                            <td data-testid="tarot-client-tfav"><center>{client.favorite && <FaStar/>}</center></td>
                            <td data-testid="tarot-client-tmail">{client.email}</td>
                            <td data-testid="tarot-client-tphone">{client.phone}</td>
                            <td data-testid="tarot-client-tdeals"><center>{client.deals}</center></td>
                        </tr>
                        )}
                    )}
                    </tbody>
                </Table>

                <Row className="d-flex">
                    <Col className="my-2 entity-footer">
                        <select name="items" id="items" value={pageSize} onChange={handleSetPageSize} 
                            className="entity-items-per" data-testid="tarot-page-size-selector">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select> записей на странице
                    </Col>

                    <Col className="entity-button-place-right d-flex">
                        <Pages page={customers.page} totalPages={customers.total_pages} />
                    </Col>
                </Row>
            </div>}
        </div>
    );
}

export default Clients;