const { TAROT_API_URL } = process.env;

function loadImages(geometry, callback) {
    let images = {};
    let loadedImages = 0;
    let numImages = 0;

    geometry.ids.map(id => {
        if (geometry.entities[id] && geometry.entities[id].card) {
            numImages++;
        }
    });

    if (numImages == 0) {
        callback(images);
    }

    geometry.ids.map(id => {
        if (geometry.entities[id] && geometry.entities[id].card) {
            images[id] = new Image();
            images[id].onload = function() {
                if(++loadedImages >= numImages) {
                    callback(images);
                }
            };
            images[id].src = `${TAROT_API_URL}/medias/${geometry.entities[id].card.pic.url}`;
        }
    })
}

export default loadImages;