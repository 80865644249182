import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveDeal, selectCommentsEditable, removeCommentEditable, 
    addCommentEditable, updateComment } from '../redux/deals/dealsSlice';
import ContentEditable from 'react-contenteditable';
import * as utils from '../helpers/utils';
import { isReadOnly } from '../helpers/deal-state-check';
import { FaPencilAlt } from "react-icons/fa";
    
const CommentsSection = (props) => {
    const dispatch = useDispatch();
    const { product, system } = props;
    const deal = useSelector(getActiveDeal);
    const commentsEditable = useSelector(selectCommentsEditable)

    const handleCommentEditableChange = (id) => {
        if(!isReadOnly(deal, product, system)) {
            let flag = commentsEditable.find(rec => rec == id);
            if (flag === undefined) {
                dispatch(addCommentEditable(id))
            } else {
                dispatch(removeCommentEditable(id))
            }
        }
    }

    const handleEditComment = (e, id) => {
        let newComment = { ...deal.comments.find(rec => rec.id == id) };
        newComment.text = e.target.value;
        dispatch(updateComment(newComment));
    }

    return (
        <>
        {deal && deal.comments &&
            <div style={{ width: "100%", paddingRight: "30px" }}>
                {deal.comments.map((comment, index) => {
                    let editable = commentsEditable.filter(rec => rec == comment.id).length > 0;
                    return (
                        <blockquote id="comment" className="tarot-comment" key={index}>
                            <div className="comment-text" data-testid="comment-text">
                                <ContentEditable
                                    html={comment.text || ""}
                                    disabled={!editable}
                                    onChange={(e) => handleEditComment(e, comment.id)}
                                    autoFocus={true}
                                /> 
                            </div>
                            <div className="comment-date">{utils.formatDateTime(comment.date)}</div>
                            <div className="comment-date">{comment.edit && `Отреактировано ${utils.formatDateTime(comment.edit)}`}</div>
                            <div className="comment-button" data-testid="comment-edit-button" 
                                onClick={() => handleCommentEditableChange(comment.id)}>
                                <FaPencilAlt />
                            </div>
                        </blockquote>
                    );
                })}
            </div>
        }
        </>
    )
}

export default CommentsSection;