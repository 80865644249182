import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { setDealToDetele, getPDFFromApi } from '../../redux/deals/dealsSlice';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import { FaHandHolding, FaTrash, FaFilePdf } from "react-icons/fa";
import * as utils from '../../helpers/utils';
import { getKeycloak } from '../../redux/settings/settingsSlice';
import { showConfirmDelete } from '../../redux/modal/modalSlice';

const ClientDeal = (props) => {
    const { deal } = props;
    const history = useHistory();
    const dispatch = useDispatch();
    const authData = useSelector(getKeycloak);
    const dealExportStatus = useSelector(state => state.deals.downloadStatus);
    const dealExportId = useSelector(state => state.deals.downloadId);

    const handleDeleteDeal = (deal) => {
        dispatch(setDealToDetele(deal))
        dispatch(showConfirmDelete())
    }

    const getPDF = (dealId) => {
		if (dealExportStatus == 'idle') {
            dispatch(getPDFFromApi({ authData: authData, dealId: dealId }))
		}
    }

    const goTo = (id) => {
        history.push(`/deal/${id}`)
    }

    return (
        <Row className='tarot-client-deal-bed'>
        {dealExportStatus == 'active' && dealExportId == deal.id &&
        <div data-testid='tarot-spinner' className='tarot-spinner-overlay'>
            <Spinner 
                as="span"
                variant="dark"
                size="sm"
                role="status"
                aria-hidden="true"
                animation="border"
            /> Скачиваю...
        </div>}
        <Col className="content mt-2 tarot-client-deal" data-testid="tarot-client-deal">
            <div>
                <Row>
                    <div style={{ width: "30px", marginLeft: "20px"  }} onClick={() => goTo(deal.id)}
                        data-testid="tarot-client-deal-icon">
                        <FaHandHolding size={30}/>
                    </div>
                    <Col>
                        <div onClick={() => goTo(deal.id)} data-testid="tarot-client-deal-type">
                            <b>{deal?.deal_type_name}</b>
                        </div>
                        <div onClick={() => goTo(deal.id)} data-testid="tarot-client-deal-question">
                            {deal?.questions && deal.questions}
                        </div>
                        <div onClick={() => goTo(deal.id)} data-testid="tarot-client-deal-date">
                            <i>{utils.formatDate(deal.date)}</i>
                        </div>
                        <div className="client-export-pdf" onClick={() => getPDF(deal.id)}
                            data-testid="tarot-export-pdf-button">
                            <FaFilePdf />
                        </div>
                        <div className="client-trash-bin" onClick={() => handleDeleteDeal(deal)}
                            data-testid="tarot-delete-deal-button">
                            <FaTrash />
                        </div>
                    </Col>
                </Row>
            </div>
        </Col>
        </Row>
    )
}

export default ClientDeal;