import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from  'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { FaFilePdf } from "react-icons/fa";
import { isExportEnabled } from '../../helpers/deal-state-check';
import { selectGeometry } from '../../redux/geometry/geometrySlice';
import { getActiveDeal, selectDealById, getPDFFromApi } from '../../redux/deals/dealsSlice';
import { getKeycloak, selectAccount } from '../../redux/settings/settingsSlice';
import { useGetDealQuery } from '../../api/dealApi';

const ButtonExportPDF = (props) => {
    const dispatch = useDispatch();
    const geometry = useSelector(selectGeometry);
    const deal = useSelector(getActiveDeal);
    const { data: currentDeal, isFetching, isSuccess } = useGetDealQuery(deal?.id, { skip: deal?.id == 0 })
    const authData = useSelector(getKeycloak);
    const dealExportStatus = useSelector(state => state.deals.downloadStatus);
    const account = useSelector(selectAccount);

    const getPDF = () => {
		if (currentDeal.id != null && dealExportStatus == 'idle') {
            dispatch(getPDFFromApi({ authData: authData, dealId: currentDeal.id }))
		}
	}

    return (
        <>
            {dealExportStatus == 'active' ?
            <Button className="tarot-button" size="sm" disabled={true} >
                <Spinner 
                    as="span"
                    data-testid="tarot-button-spinner"
                    variant="light"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    animation="border"
                /> Скачиваю...
            </Button>
            :
            <Button 
                className="tarot-button" 
                data-testid="tarot-export-deal-button"
                disabled={!isExportEnabled(geometry, deal, currentDeal, /*client,*/ account?.product)} 
                size="sm" 
                onClick={getPDF}
            >
                <FaFilePdf/> Выгрузить PDF
            </Button>
            }
        </>
    )
}

export default ButtonExportPDF;