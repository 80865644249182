import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import Button from  'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Toast from 'react-bootstrap/Toast';
import { FaSave } from "react-icons/fa";
import { selectGeometry } from '../../redux/geometry/geometrySlice';
import { showAlert } from '../../redux/modal/modalSlice';
import { getActiveDeal, selectDealById } from '../../redux/deals/dealsSlice';
import { getActiveClientId, getActiveClient } from '../../redux/client/clientSlice';
import { isSaveEnabled, isSaveEnabledWithAlert } from '../../helpers/deal-state-check';
import { useGetCustomerQuery, useAddCustomerMutation } from '../../api/clientApi';
import { useGetSelfAccountQuery } from '../../api/accountApi';
import { useAddDealMutation, useUpdateDealMutation } from '../../api/dealApi';

const ButtonSaveDeal = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [toast, setToast] = useState({});
    const [showToast, setShowToast] = useState(false);
    const geometry = useSelector(selectGeometry);
    const deal = useSelector(getActiveDeal);
    const currentDeal = useSelector(state => selectDealById(state, deal?.id));
    const activeClientId = useSelector(getActiveClientId);
    const activeClient = useSelector(getActiveClient);
    const { data: account, isSuccess: isAccountSuccess } = useGetSelfAccountQuery()
    const [addClient, { isAddClientLoading }] = useAddCustomerMutation()
    const [addDeal, { isAddLoading }] = useAddDealMutation()
    const [updateDeal, { isUpdateLoading }] = useUpdateDealMutation()

    const handleSaveDeal = async () => {
        const check = isSaveEnabledWithAlert(geometry)
        let newCustomerId = deal.customer;
        if (check.allowed) {
            if (deal?.id == 0) {
                if (deal.customer == 0) {
                    try {
                        const res = await addClient(activeClient).unwrap();
                        newCustomerId = res.id;
                    } catch {
                      setToast({
                        title: 'Не получилось...',
                        description: "Не удалось сохранить клиента, пожалуйста попробуйте еще раз.",
                        status: 'error'
                      })
                      setShowToast(true)
                    }
                }
                try {
                    const res = await addDeal({ deal: { ...deal, customer: newCustomerId }, geometry: geometry }).unwrap()
                    history.push(`/deal/${res.id}`)
                } catch {
                  setToast({
                    title: 'Не получилось...',
                    description: "Не удалось сохранить расклад, пожалуйста попробуйте еще раз.",
                    status: 'error'
                  })
                  setShowToast(true)
                }
            } else {
                try {
                    const res = await updateDeal({ deal: deal, geometry: geometry }).unwrap()
                } catch {
                  setToast({
                    title: 'Не получилось...',
                    description: "Не удалось сохранить расклад, пожалуйста попробуйте еще раз.",
                    status: 'error'
                  })
                  setShowToast(true)
                }
            }
        } else {
            dispatch(showAlert(check.message))
        }
    }

    return (
        <>
            {isAddLoading || isUpdateLoading ?
            <Button className="tarot-button" size="sm" disabled={true}>
                <Spinner 
                    as="span"
                    data-testid="tarot-button-spinner"
                    variant="light"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    animation="border"
                /> Сохраняю...
            </Button>
            :
            <Button 
                className="tarot-button" 
                data-testid="tarot-save-deal-button"
                disabled={!isSaveEnabled(geometry, deal, 
                    Object.keys(activeClient).length > 0 ? activeClient : null, currentDeal, account?.product)} 
                size="sm" 
                onClick={handleSaveDeal}
            >
                    <FaSave/> Сохранить расклад
            </Button>
            }
            <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide>
                <Toast.Header>
                    <strong className="me-auto">{toast?.title}</strong>
                </Toast.Header>
                <Toast.Body>{toast?.description}</Toast.Body>
            </Toast>
        </>
    )
}

export default ButtonSaveDeal;