import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import prepareHeaders from '../../helpers/prepare-headers';
import { apiSlice } from '../apiSlice';

const initialState = {
  newclient: {},
  activeClientId: null,
  activeClient: {},
  sortBy: [
    { field: "date", order: "desc" },
    { field: "deal_type", order: "none" }
  ],
  sortClientsBy: [
    { field: "name", order: "asc" },
    { field: "email", order: "none" },
    { field: "phone", order: "none" }, 
    { field: "sex", order: "none" }
  ],
  paging: { size: 25, start: 0, page: 1 },
  editMode: false,
  confirmClientDeleteVisible: false,
  clientToDelete: null,
  downloadStatus: 'idle',
}

const { TAROT_PDF_URL } = process.env;

const sortBy = state => state.clients.sortBy;
const sortClientsBy = state => state.clients.sortClientsBy;
const paging = state => state.clients.paging;

export const getPDFFromApi = createAsyncThunk('clients/getPDF', async (payload) => {
  if (payload.authData) {
    const {authData} = payload;
    try {
      const token = authData.apiToken.jwt;
      const response = await fetch(`${TAROT_PDF_URL}/get_customers`, {
        method: "GET",
        headers: prepareHeaders(payload.authData.apiToken.jwt)
      })
      .then(res => {
        return res.blob();
      })
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `Customers.pdf`,
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })

      return response
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response)
    } 
  }
})

export const selectDealsSortParams = createSelector(sortBy, (sortBy) => 
{
  let sorts = "";
  let index = 0;
  sortBy.forEach(sort => {
      if (sort.order != "none") {
          if (index != 0) {
              sorts = sorts + "|"
          }
          sorts = sorts + sort.field + ":" + sort.order;
          index++;
      }
  });
  return sorts;
})

export const selectSortParams = createSelector(sortClientsBy, (sortClientsBy) => 
  { 
    let sorts = "";
    let index = 0;
    sortClientsBy.forEach(sort => {
        if (sort.order != "none") {
            if (index != 0) {
                sorts = sorts + "|"
            }
            sorts = sorts + sort.field + ":" + sort.order;
            index++;
        }
    });
    return sorts;
  }
)

export const selectPaging = createSelector(paging, (paging) => 
  {
    return `size=${paging.size}&page=${paging.page}`
  }
)

const clientSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    activeClientLoaded(state, action) {
      state.activeClient = action.payload;
    },
    clientAdded(state, action) {
      clientsAdapter.addOne(state, action.payload);
      clientsAdapter.removeOne(state, 0);
    },
    clientUpdated(state, action) {
      const { name, email, phone, birthday, sex } = action.payload;
      if (sex) { state.activeClient.sex = sex }
      if (birthday) { state.activeClient.birthday = birthday }
      if (name) {
        state.activeClient.name = name 
      } else if (name === "") {
        state.activeClient.name = ""
      }
      if (email) { state.activeClient.email = email?.replace(" ", "") }
      if (phone) { state.activeClient.phone = phone }
  },
  clientDeleted(state, action) {
    let id = action.payload.id;
    if (id) {
      clientsAdapter.removeOne(state, id);
    }
  },
  clientSwapFavorite(state, action) {
    const existingClient = state.activeClient
    if (existingClient) {
      existingClient.favorite = !existingClient.favorite;
    }
  },
    sortUpdated(state, action) {
        let tmp = []
        state.sortBy.map((sort) => {
            if (sort.field === action.payload.field) {
                let newSort = {...sort, order: action.payload.order }
                tmp.push(newSort)
            } else {
                tmp.push(sort)
            }
          })
          state.sortBy = tmp
    },
    setActiveClient(state, action) {
      state.activeClient = action.payload
    },  
    setActiveClientId(state, action) {
      state.activeClientId = action.payload
    },  
    switchEditMode(state, action) {
      let currentMode = state.editMode;
      state.editMode = !currentMode;
    },
    setConfirmDeleteClient(state, action) {
      state.confirmClientDeleteVisible = action.payload;
    },
    setClientToDetele(state, action) {
      state.clientToDelete = action.payload;
    },
    clearClientToDelete(state, action) {
      state.clientToDelete = null;
    },
    clearNewClientFlag(state, action) {
      state.newclient = {};
    },
    sortClientAdded(state, action) {
      let tmp = []
      state.sortClientsBy.map((sort) => {
          if (sort.field === action.payload.field) {
              let newSort = {...sort, order: action.payload.order }
              tmp.push(newSort)
          } else {
              tmp.push(sort)
          }
      })
      state.sortClientsBy = tmp
    },
    sortClientUpdated(state, action) {
      let tmp = []
      state.sortClientsBy.map((sort) => {
          if (sort.field === action.payload.field) {
              let newSort = {...sort, order: action.payload.order }
              tmp.push(newSort)
          } else {
              tmp.push(sort)
          }
      })
      state.sortClientsBy = tmp
    },
    sortClientRemoved(state, action) {
      let tmp = []
      state.sortClientsBy.map((sort) => {
          if (sort.field === action.payload.field) {
              let newSort = {...sort, order: "none" }
              tmp.push(newSort)
          } else {
              tmp.push(sort)
          }
      })
      state.sortClientsBy = tmp
    },
    sortsClientReplaced(state, action) {
        let newSort = []
        newSort.push(action.payload)
        state.sortClientsBy = newSort
    },
    sortsClientReset(state, action) {
        state.sortClientsBy = [{ field: 'name', order: 'asc' }]
    },
    pageSizeSet(state, action) {
        state.paging.size = action.payload
    },
    pageSet(state, action) {
        var page = action.payload
        state.paging.start = state.paging.size * (page - 1)
        state.paging.page = page 
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getPDFFromApi.pending, (state, action) => {
        state.downloadStatus = 'active';
      })
      .addCase(getPDFFromApi.fulfilled, (state, action) => {
        state.downloadStatus = 'idle';              
      })
      .addMatcher(apiSlice.endpoints.addCustomer.matchFulfilled, 
        (state, { payload }) => {
          state.activeClient = payload
      }) 
      .addMatcher(apiSlice.endpoints.getCustomer.matchFulfilled, 
        (state, { payload }) => {
          state.activeClient = payload
      }) 
  }
})

export const { activeClientLoaded, clientAdded, clientUpdated, 
  clientSwapFavorite, sortUpdated, clientDeleted, clientAddDeal, 
  clientDeleteDeal, setActiveClientId, setActiveClient, switchEditMode, 
  setConfirmDeleteClient, setClientToDetele, clearClientToDelete, 
  clearNewClientFlag, sortClientAdded, sortClientRemoved, 
  sortClientUpdated, sortsClientReplaced, sortsClientReset, 
  pageSet, pageSizeSet } = clientSlice.actions;

export default clientSlice.reducer;

export const getActiveClientId = state => state.clients.activeClientId;

export const getActiveClient = state => state.clients.activeClient;

export const selectAllSorts = state => state.clients.sortClientsBy;