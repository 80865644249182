import { useSelector, useDispatch } from 'react-redux';
import { refresh } from '../redux/settings/settingsSlice';


const prepareHeaders = (token) => {
    if (token && token != "") {
        return ({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,	 
        })
    } else {
        return ({
            'Content-Type': 'application/json',	 
        })
    }
}

export default prepareHeaders;