import React, { useState, useEffect } from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const ConfirmDeleteClientModal = (props) => {
    const [visible, setVisible] = useState(props.visible);

    useEffect(
		() => {
			setVisible(props.visible);
		},
		[props],
	);

    const handleClose = () => {
        props.setVisible(false);
    }

    return (
        <Modal show={visible} size="sm" className="tarology-modal" 
            animation={false} onHide={handleClose} data-testid="modal-confirm-delete-client">
        <Modal.Header className="bg-white" closeButton>
            <Modal.Title>Удалить расклад</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row id="subscribe-confirm-body" className='mt-5'>
                <Col>
                    <p>Вы действительно хотите удалить клиента &nbsp;
                        <strong data-testid="client-name">{props.client?.name}</strong>?
                    </p>
                </Col>
            </Row>
        </Modal.Body>
        <Modal.Footer>
            <Button  className="tarot-sex-button mr-3" data-testid="modal-confirm-button" onClick={props.delete}>
                Подтвердить
            </Button>
            <Button className="tarot-red-button" data-testid="modal-cancel-button" onClick={handleClose}>
                Отменить
            </Button>
        </Modal.Footer>
        </Modal>        
    )
}

export default ConfirmDeleteClientModal;