import { createSlice, createAsyncThunk, createEntityAdapter,
  createSelector } from '@reduxjs/toolkit';
import prepareHeaders from '../../helpers/prepare-headers';

const dealsAdapter = createEntityAdapter({})

const initialState = dealsAdapter.getInitialState ({
    status: 'idle',
    error: null,
    newdeal: {},
    newDealAction: false,
    activeDeal: {
      dealTypeId: null,
      comments: []
    },
    commentsEditable: [],
    confirmDealDeleteVisible: false,
    dealToDelete: null,
    updateStatus: 'idle',
    downloadStatus: 'idle',
    downloadId: null
})

const { TAROT_PDF_URL } = process.env;

export const getPDFFromApi = createAsyncThunk('deals/getPDF', async (payload) => {
  const { dealId } = payload;
  try {
    const response = await fetch(`${TAROT_PDF_URL}/get_deal?deal_id=${dealId}`, {
      method: "GET",
      headers: prepareHeaders(sessionStorage.getItem('authToken'))
    })
    .then(res => {
      return res.blob();
    })
    .then(blob => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `Deal_${dealId}.pdf`,
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    })

    return response
  } catch (err) {
    if (!err.response) {
      throw err
    }
    return rejectWithValue(err.response)
  } 
})

const dealsSlice = createSlice({
  name: 'deals',
  initialState,
  reducers: {
    activeDealLoaded(state, action) {
      state.activeDeal = action.payload;
    },
    dealAdded(state, action) {
      dealsAdapter.addOne(state, action.payload);
    },
    dealUpdated(state, action) {
      dealsAdapter.upsertOne(state, action.payload);
    },
    dealDeleted(state, action) {
      dealsAdapter.removeOne(state, action.payload);
    },
    setActiveDeal(state, action) {
      state.activeDeal = action.payload
    },   
    addCommentEditable(state, action) {
      let currentCommentsEditable = state.commentsEditable
      currentCommentsEditable.push(action.payload)
    },
    removeCommentEditable(state, action) {
      state.commentsEditable = state.commentsEditable.filter(rec => rec != action.payload)
    },
    addComment(state, action) {
      let currentComments = state.activeDeal.comments;
      currentComments.push(action.payload)
    },
    updateComment(state, action) {
      let currentComment = state.activeDeal.comments.find(rec => rec.id == action.payload.id);
      currentComment.text = action.payload.text
    },
    removeComment(state, action) {
      state.activeDeal.comments = state.activeDeal.comments.filter(rec => rec.id != action.payload.id);
    },
    setConfirmDelete(state, action) {
      state.confirmDealDeleteVisible = action.payload;
    },
    setDealToDetele(state, action) {
      state.dealToDelete = action.payload;
    },
    clearDealToDelete(state, action) {
      state.dealToDelete = null;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getPDFFromApi.pending, (state, action) => {
        state.downloadId = action.meta.arg.dealId;
        state.downloadStatus = 'active';
      })
      .addCase(getPDFFromApi.fulfilled, (state, action) => {
        state.downloadId = null;
        state.downloadStatus = 'idle';
      })
      .addCase('API_DEAL_FLAG_SET', (state, action) => {
        state.newDealAction = true
      })
      .addCase('API_DEAL_FLAG_REMOVE', (state, action) => {
        state.newDealAction = false
      })
  }
})

export const { activeDealLoaded, dealAdded, dealUpdated, dealDeleted, 
  setActiveDeal, addCommentEditable, removeCommentEditable, addComment, 
  updateComment, removeComment, setConfirmDelete, setDealToDetele,
  clearDealToDelete } = dealsSlice.actions;

export default dealsSlice.reducer;

export const {  selectAll: selectAllDeals,
  selectById: selectDealById,
  selectIds: selectDealIds
} = dealsAdapter.getSelectors(state => state.deals)

export const selectCommentsEditable = state => state.deals.commentsEditable;

export const selectDealToDelete = state => state.deals.dealToDelete;

export const getActiveDeal = state => state.deals.activeDeal;

export const getZeroDeal = state => 
{
  return (state.deals.entities[0])
};

const dealsSelectors = dealsAdapter.getSelectors(state => state.deals);
const selectDeals = state => dealsSelectors.selectAll(state);  
const clientId = (state, clientId) => clientId;
export const selectDealsByClient = createSelector(selectDeals, clientId, (collection, clientId) => 
  {
    let deals = collection.filter(deal => deal.customer.id == clientId);
    return deals
  }
)