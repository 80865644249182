import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import * as geo from '../../helpers/geometry';

const geometryAdapter = createEntityAdapter({
    selectId: (position) => position.position,
})

const extrasAdapter = createEntityAdapter({
  selectId: (position) => position.position,
})

const initialState = geometryAdapter.getInitialState({
  geo: {}
})

Path2D.prototype.roundRectRotated = function (x, y, w, h, rg, crn) {
  if (w < 2 * crn) crn = w / 2;
  if (h < 2 * crn) crn = h / 2;
  let hwh = w / 2;
  let hhh = (h - (2 * crn)) / 2;
  let hwv = (w - (2 * crn)) / 2;
  let hhv = h / 2;
  let hw = w / 2;
  let hh = h / 2;
  let r = rg * Math.PI / 180;
  let c = Math.cos(r);
  let s = Math.sin(r);
  let rh1x = - hwh * c - hhh * s;
  let rh1y = - hwh * s + hhh * c;
  let rh2x = hwh * c - hhh * s;
  let rh2y = hwh * s + hhh * c;
  let rv1x = - hwv * c - hhv * s;
  let rv1y = - hwv * s + hhv * c;
  let rv2x = hwv * c - hhv * s;
  let rv2y = hwv * s + hhv * c;
  let r1x = - hw * c - hh * s;
  let r1y = - hw * s + hh * c; 
  let r2x = hw * c - hh * s;
  let r2y = hw * s + hh * c;
  this.moveTo(x + rh1x, y + rh1y);
  this.arcTo(x + r1x, y + r1y, x + rv1x, y + rv1y, crn);
  this.lineTo(x + rv2x, y + rv2y);
  this.arcTo(x + r2x, y + r2y, x + rh2x, y + rh2y, crn);
  this.lineTo(x - rh1x, y - rh1y);
  this.arcTo(x - r1x, y - r1y, x - rv1x, y - rv1y, crn);
  this.lineTo(x - rv2x, y - rv2y);
  this.arcTo(x - r2x, y - r2y, x - rh2x, y - rh2y, crn);
  this.closePath();

  let ox1 = Math.min(x + r1x, x + r2x, x - r1x, x - r2x);
  let oy1 = Math.min(y + r1y, y + r2y, y - r1y, y - r2y);
  let ox2 = Math.max(x + r1x, x + r2x, x - r1x, x - r2x);
  let oy2 = Math.max(y + r1y, y + r2y, y - r1y, y - r2y);

  let cx = ox1 + (ox2 - ox1) / 2;
  let cy = oy1 + (oy2 - oy1) / 2;

  this.center = { x: cx, y: cy }

  return this;
}

const geometrySlice = createSlice({
  name: 'geometry',
  initialState,
  reducers: {
    fillGeometry(state, action) {
      const {positions, ...newVals} = action.payload.geometry;
      const stage = action.payload.geometry.stage;
      const keep = action.payload.keepCards;
      const positionEntries = positions.map(pos => {
        let rot = geo.applyRotation(pos);
        let newGeo = {...pos};
        newGeo.offsetX = rot.offX;
        newGeo.offsetY = rot.offY;
        newGeo.x = rot.x;
        newGeo.y = rot.y;
        newGeo.rotation = rot.rotation;

        let path = new Path2D();
        path.roundRectRotated(rot.x, rot.y, pos.width, pos.height, rot.rotation, pos.width / 12);
        newGeo.path = path;

        if (!keep) {
          newGeo.card = null;
          newGeo.extra_deal = null;
          newGeo.extra_deal_type = null;
        } else {
          let existingPosition = state.entities[pos.position]
          if (existingPosition) {
            newGeo.card = existingPosition.card;
            newGeo.extra_deal = existingPosition.extra_deal;
            newGeo.extra_deal_type = existingPosition.extra_deal_type; 
          }
        }
        if (state.entities[pos.position]?.extra?.ids.length > 0) {          
            newGeo.extra = state.entities[pos.position].extra;
        } else {
            newGeo.extra = extrasAdapter.getInitialState();
        }
        return newGeo;
      });

      let hintBtn = new Path2D();
      hintBtn.roundRectRotated(stage ? stage.width - 30 : 70, 30, 50, 50, 0, 12);

      let plusBtn = new Path2D();
      plusBtn.roundRectRotated(stage ? stage.width - 30 : 70, 
        stage ? stage.height - 30 : 70,  50, 50, 0, 12);

      geometryAdapter.setAll(state, positionEntries);
      state.geo = {...state.geo, ...newVals, hintBtn: hintBtn, plusBtn: plusBtn};
    },
    resetGeometry(state, action) {
      geometryAdapter.removeAll(state);
      state.geo = {};
    },
    fillPositions(state, action) {
      const positions = action.payload.map(extra => {
        let pos = state.entities[extra.position]
        if (pos) {
          pos.inverted = extra.inverted
          let rot = geo.applyRotation(pos);
          return { 
            card: extra.card,
            inverted: extra.inverted,
            question: extra.question,
            extra_deal: extra.extra_deal,
            extra_deal_type: extra.extra_deal_type,
            position: pos.position,
            rotation: rot.rotation
          }  
        }
      })
      geometryAdapter.upsertMany(state, positions);
      action.payload.map(extra => {
        let pos = state.entities[extra.position].extra;
        extrasAdapter.upsertMany(pos, extra.extra_refs);
      })
    },
    fillPosition(state, action) {
      const {position, card, extra} = action.payload;
      if (extra) {
        let existingPosition = state.entities[extra]
        let existingExtra = existingPosition.extra?.entities[position];
        extrasAdapter.upsertOne(existingPosition.extra, { ...existingExtra, card: card })
      } else {
        let existingPosition = state.entities[position];
        geometryAdapter.upsertOne(state, { ...existingPosition, card: card });
      }
    },
    invertPosition(state, action) {
      if (action.payload.position) {
        let extra = action.payload.extra;
        let pos = action.payload.position;
        if (extra) {
          let position = state.entities[extra].extra.entities[pos];
          position.inverted = !position.inverted;
          let rot = geo.applyRotation(position);
          position.rotation = rot.rotation;
        } else {
          let position = state.entities[pos];
          position.inverted = !position.inverted;
          let rot = geo.applyRotation(position);
          position.rotation = rot.rotation;
        }
      }
    },
    increaseLimit(state, action) {
      const extra = action.payload?.extra;
      const newVal = action.payload?.newVal;
      if (extra) {
        let existingPosition = state.entities[extra];
        if (existingPosition) {
          if (newVal) {
            existingPosition.limit = newVal;
          } else {
            existingPosition.limit = existingPosition.limit + 1;
          }          
        }
      } else {
        if (newVal) {
          state.limit = newVal;
        } else {
          state.limit = state.limit + 1;
        }
      }
    },
    addQuestion(state, action) {
      const {position, question} = action.payload;
      let existingPosition = state.entities[position];
      geometryAdapter.upsertOne(state, { ...existingPosition, question: question })
    },
    resetLimit(state, action) {
      state.limit = 0;
    },
    addExtraDeal(state, action) {
      const {pos, type} = action.payload;
      let existingPosition = state.entities[pos];
      geometryAdapter.upsertOne(state, { 
        ...existingPosition, 
        limit: 0, 
        extra_deal_type: type, 
        extra: extrasAdapter.getInitialState() 
      })
    },
    removeExtraDeal(state, action) {
      let pos = action.payload.pos;
      let existingPosition = state.entities[pos];
      geometryAdapter.upsertOne(state, { 
        ...existingPosition, 
        limit: 0, 
        extra_deal_type: null, 
        extra: extrasAdapter.getInitialState() 
      })
    },
    switchExtraHeader(state, action) {
      const {pos, card} = action.payload;
      let existingCard = state.entities[pos].extra.entities[card];
      if (existingCard) {
        if (existingCard.expanded != null) {
          existingCard.expanded = !existingCard.expanded;
        } else {
          existingCard.expanded = false;
        }
      }
    },
    fillExtraGeometry(state, action) {
      const {geometry, position, selectable, fixed, card} = action.payload;
      let existingPosition = state.entities[position].extra;
      if (existingPosition) {
        existingPosition.geo = { ...existingPosition.geo, ...geometry };
        existingPosition.geo.selectable = selectable;
        const stage = existingPosition.geo.stage;
        geometry.positions.map(pos => {
          let newPos = {...pos}
          newPos.inverted = existingPosition.entities[pos.position]?.inverted
          let rot = geo.applyRotation(newPos);
          let newGeo = {...pos};
          newGeo.offsetX = rot.offX;
          newGeo.offsetY = rot.offY;
          newGeo.x = rot.x;
          newGeo.y = rot.y;
          newGeo.rotation = rot.rotation;
          newGeo.inverted = newPos.inverted;
  
          let path = new Path2D();
          path.roundRectRotated(rot.x, rot.y, pos.width, pos.height, rot.rotation, pos.width / 12);
          newGeo.path = path;
          if (pos.position == fixed) {
            extrasAdapter.upsertOne(existingPosition, {...newGeo, card: card})
          } else {
            extrasAdapter.upsertOne(existingPosition, newGeo)
          }
        })
        let plusBtn = new Path2D();
        plusBtn.roundRectRotated(stage ? stage.width / 2 : 50, 
          stage ? stage.height + 20 : 120,  50, 50, 0, 12); 
        existingPosition.geo.plusBtn = plusBtn;
      }
    },
    chooseExtraCard(state, action) {
      const { extra, position } = action.payload;
      let existingExtra = state.entities[extra];
      existingExtra.extra.ids.map((id) => {
        let pos = existingExtra.extra.entities[id];
        if (pos.position == position) {
          pos.selected = true;
          pos.expanded = true;
        } else {
          pos.selected = false;
          pos.expanded = false;
        }
      })
    },
  }
})

export const { fillGeometry, resetGeometry, fillPositions,fillPosition, addQuestion,
invertPosition, increaseLimit, resetLimit, addExtraDeal, removeExtraDeal,
switchExtraHeader, fillExtraGeometry, resetExtraGeometry, chooseExtraCard,
addPath } = geometrySlice.actions

export default geometrySlice.reducer

export const { selectAll: selectAllPositions,
  selectById: selectPositionById,
  selectIds: selectPositionIds
} = geometryAdapter.getSelectors(state => state.geometry)

export const selectGeometry = state => state.geometry;

export const selectLimit = (state, position) => {
  if (position) {
    return state.geometry.entities[position].limit || 0
  } else {
    return state.geometry.limit || 0
  }
}

export const selectPosition = (state, position) => {
  return state.geometry.entities[position]
}

export const selectExtraGeometry = (state, position) => {
  return state.geometry.entities[position]?.extra;
}

export const selectExtraPosition = (state, extra, position) => {
  return state.geometry.entities[extra]?.extra?.entities[position];
}

export const selectExtraCard = (state, extra, position) => {
  return null;
}

export const selectExtraCards = (state, extra) => {
  return state.geometry.entities[extra]?.extra?.entities;
}
