import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker, { registerLocale } from "react-datepicker";
import ru from 'date-fns/locale/ru';
import { getActiveDeal, setActiveDeal } from '../../redux/deals/dealsSlice';

import "react-datepicker/dist/react-datepicker.css";

const DateSelector = (props) => {
    const dispatch = useDispatch();
    const deal = useSelector(getActiveDeal);

    registerLocale('ru', ru);

    return (
        <div>
            <DatePicker 
                locale="ru"
                dateFormat="dd/MM/yyyy"
                className="datepicker"
                selected={deal.date ? new Date(deal.date) : new Date()} 
                onChange={(date) => dispatch(setActiveDeal({...deal, date: date.toISOString() }))} 
            />
        </div>
    )
}

export default DateSelector;