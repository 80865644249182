import React from 'react';
const ReactDOM = require('react-dom');
import { BrowserRouter } from "react-router-dom";
import './scss/custom.scss';
import './css/modal.css';
import App from './App';
import store from './redux/store'
import { Provider } from 'react-redux'

ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter basename="/">
			<App />
		</BrowserRouter>
	</Provider>,
	document.getElementById('root')
);
