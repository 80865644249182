import React, { useState, useEffect } from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const SubscribeModal = (props) => {
    const [visible, setVisible] = useState(props.visible);
    const [termsVisible, setTermsVisible] = useState(false);
    const [theService, setTheService] = useState(props.theService);
    const [thePrice, setThePrice] = useState(props.price);

    useEffect(
		() => {
			setVisible(props.visible);
            setTheService(props.theService);
            setThePrice(props.price);
		},
		[props],
	);

    const handleClose = () => {
        setVisible(false);
    }

    return (
        <Modal show={visible} className="tarology-modal" animation={false} onHide={handleClose} data-testid="subscribe-modal">
        <Modal.Header className="bg-white" closeButton>
            <Modal.Title>Оформить подписку</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row id="subscribe-confirm-body">
                <Col>
                    <p>Вы действительно хотите перейти на подписку уровня <strong>{theService?.name || ""}</strong>?</p>
                    <p>Абонентская плата составит {thePrice?.amount} {thePrice?.currency} в месяц.</p>
                </Col>
            </Row>
            <Row id="subscribe-confirm-terms-h" onClick={() => setTermsVisible(!termsVisible)}>
                <Col>
                    Условия предоставления сервиса {termsVisible ? <FaChevronDown/> : <FaChevronUp />}
                </Col>
            </Row>
            {termsVisible &&
            <Row id="subscribe-confirm-terms">
                <Col>
                    Тут текст
                </Col>
            </Row>}
        </Modal.Body>
        <Modal.Footer>
            <Button  className="tarot-sex-button mr-3" onClick={props.subscribe} data-testid="subscribe-modal-ok">
                Подтвердить
            </Button>
            <Button className="tarot-red-button" onClick={handleClose}>
                Отменить
            </Button>
        </Modal.Footer>
        </Modal>        
    )
}

export default SubscribeModal;