import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useConfirmMutation, useRerequestMutation } from '../api/authApi';

const Confirm = (props) => {
    let query = useLocation()
    const [email, setEmail] = useState("")
    const [step, setStep] = useState("checkToken");
    const [error, setError] = useState(null)
    const history = useHistory();
    
    const [confirm, { isLoading }] = useConfirmMutation();
    const [rerequest] = useRerequestMutation();

	useEffect(
		async () => {
            let search = query.search;
            if (search.charAt(0) === "?") { search = query.search.substring(1) }
            let attr = search.split("=")
            const token = attr[1];

            try {
                await confirm({ token: token }).unwrap()
                setError("Ok")
            } catch (err) {
                if (err.status == 401) {
                    setError(
                        <>Токен некорректный или устарел. Возможно, надо
                            <span className="tarot-intro-link" 
                                onClick={async () => { 
                                    setStep("Ask mail"); 
                                    setError(null);
                                }}> запросить новый</span>.</>
                    )
                } else {
                    setError("Что-то пошло не так...")
                }
            }
		}, [query]
	)

    useEffect(
        () => {
            if (error == "Ok") {
                history.push(`/login`)
            }
        }, [error]
    )

    return (
        <>
        <div className="splash-body" style={{ background: `url("/image/tarology-bg.jpg")`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
        <div className='splash-card'>
                <div className='splash-logo'>
                    <Image src='/image/tarology.svg' fluid />
                </div>
                <p className='tarot-intro-title'>Подтверждение адреса e-mail</p>
                {step == "Ask mail" &&
                    <>
                        <Row className="tarot-intro-row">
                            <Col>
                                <p className='tarot-modal-p'><small>Введите пожалуйста адрес e-mail, который был указан при регистрации.</small></p>
                            </Col>
                        </Row>
                        <Row className="tarot-intro-row">
                            <Col>
                                <p className='tarot-modal-p'><small>E-mail</small></p>
                                <input 
                                    type="text" 
                                    value={email}
                                    className="tarot-modal-input"
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <p></p>
                            </Col>
                        </Row>
                        <Button className="tarot-intro-button tarot-sex-button" onClick={async () => {
                            try {
                                await rerequest({ email: email }).unwrap()
                                setError("Новое письмо выслано!")
                            } catch (err) {
                                switch (err.status) {
                                    case 401:
                                        setError("Пользователь с таким e-mail не зарегистрирован.")
                                        break;
                                    default:
                                        setError("Что-то пошло не так...")
                                }
                            }
                        }} 
                        data-testid="modal-button-ok">
                        Отправить
                        </Button>
                    </>
                }
                {error &&
                    <Row id="subscribe-confirm-body">
                        <Col>
                            <center>
                                <p className='tarot-modal-p-error'>
                                    <small>{error}</small>
                                </p>
                            </center>
                        </Col>
                    </Row>
                }
            </div>
        </div>
        </>
    )
}

export default Confirm;