import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from  'react-bootstrap/Button';
import { FaComment } from "react-icons/fa";
import { getActiveDeal, addCommentEditable, addComment } from '../../redux/deals/dealsSlice';
import { isReadOnly } from '../../helpers/deal-state-check';

const ButtonAddComment = (props) => {
    const {deal, product, system} = props
    const dispatch = useDispatch();

    const handleAddComment = () => {
        let newIds = deal.comments.filter(rec => rec.id.toString().substring(0, 6) == "new-id")
        let newId = 0
        newIds.map((comment) => {
            let tmp = parseInt(comment.id.split("_")[1])
            if (tmp > newId) { newId = tmp }
        })
        newId = newId + 1
        dispatch(addComment({ id: "new-id_" + newId, text: "", date: new Date().toJSON(), editable: true }));
        dispatch(addCommentEditable("new-id_" + newId));
    }

    return (
        <>
        <Button 
            className="tarot-button" 
            data-testid="add-comment-button"
            size="sm" 
            disabled={isReadOnly(deal, product, system)}
            onClick={handleAddComment}
        >
            <FaComment/> Добавить комментарий
        </Button>
        </>
    )
}

export default ButtonAddComment;