import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { ExpandedContext } from '../expanded-context';
import { FaCheck } from "react-icons/fa";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from 'react-bootstrap/Button';
import Toast from 'react-bootstrap/Toast';
import SubscribeModal from '../modals/SubscribeModal';
import { useGetSelfAccountQuery, useGetCatalogQuery, 
    useUpdateSubscriptionMutation } from '../api/accountApi';

import '../scss/custom.scss';
import "react-datepicker/dist/react-datepicker.css";
import '../css/tile.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import '../css/nkp.css';

const { TAROT_API_URL } = process.env;
  
const Subscribe = (props) => {
    const [expanded, setExpanded] = useContext(ExpandedContext);
    const [modalVisible, setModalVisible] = useState(false);
    const [theService, setTheService] = useState(null);
    const [thePrice, setThePrice] = useState(null);
    const { data: account, isSuccess: isAccountSuccess } = useGetSelfAccountQuery()
    const [toast, setToast] = useState({});
    const [showToast, setShowToast] = useState(false);
    const changeInProgress = useSelector(state => state.settings.changeInProgress);
    const { data: catalog, isSuccess: isCatalogSuccess } = useGetCatalogQuery()
    const [updateSubscription, { isUpdateLoading }] = useUpdateSubscriptionMutation()

    const history = useHistory();

    const formWord = (num) => {
        switch (num) {
            case 1:
                return "колода"
            case 2:
            case 3:
            case 4:
                return "колоды"
            default:
                return "колод"
        }
    }

    const formDealTypes = (num) => {
        switch (num) {
            case 1:
            case 21:
                return "вариант расклада"
            case 2:
            case 3:
            case 4:
            case 22:
            case 23:
            case 24:
                return "варианта раскладов"
            case 5:
            case 6:
            case 7:
            case 8:
            case 9:
            case 10:
            case 11:
            case 12:
            case 13:
            case 14:
            case 15:
            case 16:
            case 17:
            case 18:
            case 19:
            case 20:
                return "вариантов раскладов"
            default:
                return "вариантов раскладов"
        }
    }

    const confirmSubscribe = (service) => {
        setTheService(service)
        setModalVisible(true)
        let price = service.prices.find(rec => rec.currency == account.currency);
        setThePrice(price)
    }

    const handleSubscribe = async () => {
        try {
            await updateSubscription(theService.guid).unwrap()
            setModalVisible(false);
            history.push(`/profile`)
        } catch {
          setToast({
            title: 'Не получилось...',
            description: "Не удалось загрузить аватар, пожалуйста попробуйте еще раз.",
            status: 'error'
          })
          setShowToast(true)
        }
    }

    const buttonBlock = (serviceClass) => {
        if (changeInProgress) {
            return (
                <div className='subscription-action'>
                    <span className='subscription-current'>Идет изменение тарифа</span>
                </div>
            )
        }

        return (
            <>
                {account?.product?.pretty_name == serviceClass?.name && account?.product?.phase == "ACTIVE" ?
                <div className='subscription-action'>
                    <FaCheck/> <span className='subscription-current'>Ваш текущий тариф</span>
                </div>
                :
                <Button className="tarot-sex-button" size="sm" 
                    onClick={() => confirmSubscribe(serviceClass)}
                    data-testid="tx-service-button-class">
                    <FaCheck/> Подключить
                </Button>}
            </> 
        )
    }

    return (
        <div className={expanded ? "tarot-content-expanded" : "tarot-content"}>
            <div className="content mt-3">
                <Row className="mb-5 w-100 subscription-container">
                    {catalog?.product.map((serviceClass, index) => {
                        if (serviceClass.public) {
                        let price = serviceClass.prices?.find(rec => rec.currency == account?.currency);
                            return (
                                <Col key={index} className="subscription-type" data-testid="tx-service-class">
                                <div id="subscription-section">
                                        <div>
                                            <div className='subscription-name' data-testid='subscription-name'>
                                                {serviceClass.name}
                                            </div>
                                            <div className='icon'>
                                                {serviceClass.icon && <img src={`${TAROT_API_URL}/medias/${serviceClass.icon}`} />}
                                            </div>
                                        </div>
                                        <div id="subscription-description">
                                            <div>
                                            {(account?.product?.phase != "ACTIVE" && account?.product?.phase != "TRIAL") &&
                                            <div className='subscription-trial'>
                                                {serviceClass.trialDisclaimer}
                                            </div>}
                                            <div className='field' data-testid='subscription-decks'>
                                                {serviceClass.ACCESS_DECK.length} {formWord(serviceClass.ACCESS_DECK.length)}:
                                                <ul>
                                                    {serviceClass.ACCESS_DECK.map((system, index) => {
                                                        return (<li key={index}>{system}</li>)
                                                    })}
                                                </ul>
                                            </div> 
                                            <div className='field' data-testid='subscription-deals'>
                                                {serviceClass.ACCESS_DEAL_TYPE.length} {formDealTypes(serviceClass.ACCESS_DEAL_TYPE.length)}
                                            </div> 
                                            {serviceClass.features.map((feature, index) => {
                                                return (
                                                    <div className='field' key={index}>
                                                        {feature}
                                                    </div>
                                                )
                                            })}
                                            </div>
                                        </div>
                                        <div id="subscription-footer">
                                            <div className='field'>
                                                Абонентская плата
                                            </div> 
                                            <div className='subscription-price' data-testid='subscription-price'>
                                                {price?.amount} {price?.currency}
                                            </div> 
                                            <div className='field'>
                                                в месяц
                                            </div> 
                                            <div className='field' data-testid='subscription-button'>
                                                {buttonBlock(serviceClass)}
                                            </div> 
                                        </div>
                                    </div>
                                </Col>
                            )
                        }
                    })}
                </Row> 
                <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide>
                    <Toast.Header>
                        <strong className="me-auto">{toast?.title}</strong>
                    </Toast.Header>
                    <Toast.Body>{toast?.description}</Toast.Body>
                </Toast>
            </div>

            <SubscribeModal visible={modalVisible} theService={theService} price={thePrice} subscribe={handleSubscribe}/>

        </div>
        
    );
}

export default Subscribe;