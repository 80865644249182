const Country = {
    austria: "Австрия",
    azerbaijan: "Азербайджан",
    albania: "Албания",
    andorra: "Андорра",
    armenia: "Армения",
    belgium: "Бельгия",
    belarus: "Белоруссия",
    bulgaria: "Болгария",
    bosnia: "Босния и Герцеговина",
    vatican: "Ватикан",
    uk: "Великобритания",
    hungary: "Венгрия",
    germany: "Германия",
    greece: "Греция",
    georgia: "Грузия",
    denmark: "Дания",
    israel: "Израиль",
    ireland: "Ирландия",
    iceland: "Исландия",
    spain: "Испания",
    italy: "Италия",
    kazakhstan: "Казахстан",
    canada: "Канада",
    cyprus: "Кипр",
    china: "Китай",
    latvia: "Латвия",
    lithuania: "Литва",
    liechtenstein: "Лихтенштейн",
    luxembourg: "Люксембург",
    malta: "Мальта",
    moldova: "Молдавия",
    monaco: "Монако",
    netherlands: "Нидерланды",
    norway: "Норвегия",
    poland: "Польша",
    portugal: "Португалия",
    russia: "Россия",
    romania: "Румыния",
    sanmarino: "Сан-Марино",
    macedonia: "Северная Македония",
    serbia: "Сербия",
    slovakia: "Словакия",
    slovenia: "Словения",
    usa: "Соединенные Штаты Америки",
    turkey: "Турция",
    ukraine: "Украина",
    finland: "Финляндия",
    france: "Франция",
    chroatia: "Хорватия",
    montenegro: "Черногория",
    czech: "Чехия",
    switzerland: "Швейцария",
    sweden: "Швеция",
    estonia: "Эстония",
    japan: "Япония"
}

export default Country