import React, { useState, useEffect } from 'react';
import {Redirect, Switch, Route, useHistory, useLocation } from "react-router-dom";
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import { socketAuthenticated } from './redux/socket/socket';
import useRoutes from './routes';
import { ExpandedContext } from './expanded-context';
import { QueryClient, QueryClientProvider } from "react-query";
import { useSelector, useDispatch } from 'react-redux';
import { selectTheme, changeTheme, getKeycloak,
	setAuthenticated } from './redux/settings/settingsSlice';
import ConfirmDelete from './modals/ConfirmDelete';
import Alert from './modals/Alert';
import SocketConnection from './SocketConnection';
import { useGetSelfAccountQuery } from './api/accountApi';

import './scss/custom.scss';
import './css/nkp.css';
import './css/tile.css';

const App = (props) => {
	let location = useLocation();

	const [expanded, setExpanded] = useState(true);
	const [flag, setFlag] = useState(true);
	const [menuOpen, setMenuOpen] = useState(false);
    const history = useHistory();

	const queryClient = new QueryClient();
	const routes = useRoutes(props);

    const dispatch = useDispatch();
	const authData = useSelector(getKeycloak);
	const { data: account, isSuccess: isAccountSuccess } = useGetSelfAccountQuery()
	const isAuthenticated = useSelector(state => state.settings.authenticated); 
    const settingsStatus = useSelector(state => state.settings.status);
    const scStatus = useSelector(state => state.settings.catalog_status);
	const theme = useSelector(selectTheme);

	const { TAROT_SOCKET_URL } = process.env;

	useEffect(
		() => {
			let accessToken = localStorage.getItem("accessToken")
			if (accessToken) {
				dispatch(setAuthenticated(true))
			} else {
				if (!isAuthenticated && 
					location.pathname != "/register" && 
					location.pathname != "/confirm" &&
					location.pathname != "/reminder") {
					history.push(`/login`)
				} 
			}
		}, [isAuthenticated]
	)

	useEffect(
		() => {
			let theme = localStorage.getItem("theme")
			if (theme != null) {
				dispatch(changeTheme(theme))
			}
		},[]
	)

	useEffect(() => {
        if (scStatus === 'idle') {
            dispatch(fetchCatalog(authData))
        }
    }, [scStatus, dispatch])

	useEffect(
		() => {
			document.documentElement.style.setProperty('--tr-primary-color', theme?.c1);
			document.documentElement.style.setProperty('--tr-secondary-color', theme?.c2);
			document.documentElement.style.setProperty('--tr-light-color', theme?.c3);
		}, [theme]
	)

	useEffect(() => {
		dispatch(socketAuthenticated())
    }, [settingsStatus, dispatch])

	return (
		<>
		<SocketConnection host={`${TAROT_SOCKET_URL}`} >
			<ExpandedContext.Provider value={[expanded, setExpanded]}>
				<QueryClientProvider client={queryClient}>
					<Switch>
						{routes.map((route, index) => {
							if (route.redirect == null) {
								return(
									<Route
										key={index}
										path={route.path}
										exact={route.exact}
										render={(props) => {
											return(
												<>
												{route.header && <Header setMenuOpen={setMenuOpen} menuOpen={menuOpen} />}
												{route.sidebar ?
												<div className="wrapper">
													<Sidebar refresh={flag} menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
													{route.main}
												</div>
												:
												<>
													{route.main}
												</>
												}
												</>
											)
										}}
									/>
								)
							} else {
								return(
									<Route
										key={index}
										path={route.path}
										exact={route.exact}
									>
										{route.redirect != null &&
										<Redirect to={route.redirect} />}
									</Route>
								)
							}
						})}
					</Switch>

					<ConfirmDelete />
					<Alert />
				</QueryClientProvider>
			</ExpandedContext.Provider>
		</SocketConnection>
		</>
	);
}

export default App;