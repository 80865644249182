import { apiSlice } from '../redux/apiSlice'

const extraDealTypeApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getExtraDealTypes: builder.query({
        query: () => '/extra-deal-types',
        providesTags: (result, error, id) => [{ type: 'ExtraDealTypes', id: 'LIST' }],
        keepUnusedDataFor: 36000
    }),
    getExtraDealType: builder.query({
        query: extraDealTypeId => `/extra-deal-types/${extraDealTypeId}`,
        providesTags: (result, error, id) => [{ type: 'ExtraDealTypes', id }],
        keepUnusedDataFor: 36000
    }),
  }),
  overrideExisting: false,
})

export const { useGetExtraDealTypeQuery, useGetExtraDealTypesQuery } = extraDealTypeApi