import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from  'react-bootstrap/Button';
import { FaTrashAlt } from "react-icons/fa";
import { getActiveDeal } from '../../redux/deals/dealsSlice';
import { showConfirmDelete } from '../../redux/modal/modalSlice';

const ButtonDeleteDeal = (props) => {
    const isDisabled = props.disabled
    const dispatch = useDispatch();
    const deal = useSelector(getActiveDeal);

    const handleDelete = () => {
        dispatch(showConfirmDelete())
    }

    return (
        <Button 
            className="tarot-button-red" 
            data-testid="tarot-delete-deal-button"
            disabled={deal.id == null || deal.id == 0 || isDisabled} 
            size="sm" 
            onClick={handleDelete}
        >
            <FaTrashAlt/> Удалить
        </Button>
    )
}

export default ButtonDeleteDeal;