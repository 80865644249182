import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { clientUpdated } from '../../redux/client/clientSlice';

const SexSelector = (props) => {
    const { client } = props;
    const dispatch = useDispatch();
    const editMode = useSelector(state => state.clients.editMode);

    return (
        <div data-testid='tarot-sex-selector'>
            <b>Пол:</b>
            {editMode?
            <DropdownButton
                onSelect={(i) => dispatch(clientUpdated({ id: client?.id, sex: i }))}
                title={client?.sex != null ? client?.sex : "Укажите пол"}
                size="sm"
                className="pb-2 tarot-sex-dropdown"
                variant={"primary"}
                style={{ display: "inline" }}
            >
                <Dropdown.Item data-testid='tarot-sex' eventKey="Мужской">Мужской</Dropdown.Item>
                <Dropdown.Item data-testid='tarot-sex' eventKey="Женский">Женский</Dropdown.Item>
            </DropdownButton>
            :
            <span style={{ marginLeft: "10px" }}>
                {client?.sex}
            </span>}
        </div>
    )
}

export default SexSelector;