import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clientUpdated } from '../../redux/client/clientSlice';
import ContentEditable from "react-contenteditable";

const EmailControl = (props) => {
    const { client } = props;
    const dispatch = useDispatch();
    const editMode = useSelector(state => state.clients.editMode);

    return (
        <div className='tarot-email-control' data-testid="tarot-email-control">
            <b>E-mail:</b> 
            <ContentEditable
                html={client?.email || ""}
                disabled={!editMode}
                className="tarot-client-field"
                onChange={(e) => dispatch(clientUpdated({ id: client.id, email: e.target.value.replace("<br>", "") }))}
            />                     
        </div>
    )
}

export default EmailControl;