import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setConfirmDeleteClient, setClientToDetele } from '../../redux/client/clientSlice';
import { selectProduct } from '../../redux/settings/settingsSlice';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { FaTrash } from "react-icons/fa";
import { useDeleteCustomerMutation } from '../../api/clientApi';

const ClientDeleteButton = (props) => {
    const { client } = props;

    const dispatch = useDispatch();
    const product = useSelector(selectProduct);
    const [deleteCustomer, { isDeleteLoading }] = useDeleteCustomerMutation()

    const handleDelete = () => {
        if (client.id != null && client.id != 0) {
            dispatch(setClientToDetele(client));
            dispatch(setConfirmDeleteClient(true));
        }
    }

    return (
        <>
            {isDeleteLoading ?
            <Button className="tarot-red-button" size="sm" disabled={true} data-testid="tarot-delete-client-button">
                <Spinner 
                    as="span"
                    variant="light"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    animation="border"
                    data-testid="tarot-delete-client-spinner"
                /> Удаляю...
            </Button>
            :
            <Button 
                className="tarot-red-button" 
                size="sm" 
                onClick={handleDelete} 
                data-testid="tarot-delete-client-button"
                disabled={product?.phase == "SUSPENDED" || product?.phase == "BLOCKED" || product?.name == "DEMO"}
            >
                <FaTrash/> Удалить
            </Button>
            }
        </>
    )
}

export default ClientDeleteButton;