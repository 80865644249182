import { apiSlice } from '../redux/apiSlice'

const articleApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getArticle: builder.query({
        query: cardId => `/articles/card/${cardId}`,
        providesTags: (result, error, id) => [{ type: 'Articles', id }],
        keepUnusedDataFor: 36000
    }),
  }),
  overrideExisting: false,
})

export const { useGetArticleQuery } = articleApi