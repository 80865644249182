import React from 'react';
import { useDispatch } from 'react-redux';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { pageSet } from '../redux/client/clientSlice';    

const Pages = ({ page, totalPages }) => {
    const dispatch = useDispatch();

    const handleGoToPage = (page) => {
        dispatch(pageSet(page));
    }

    if(page > totalPages) {
        handleGoToPage(1)
    }

    var pageNums = [];
    var nums = [];
    if (totalPages > 1) {
        for (var i = 2; i < totalPages; i++) {
            nums.push(i)
        }    
    }

    if (page == 1) {
        pageNums.push(<div key={0}><FiChevronLeft data-testid="pg-left" /></div>);
        pageNums.push(<div className="entity-pagination-filler" data-testid="pg-filler" key={1}>{1}</div>)
    } else {
        pageNums.push(<div key={0}><FiChevronLeft data-testid="pg-left" className="active" onClick={() => handleGoToPage(page - 1)}/></div>);
        pageNums.push(<div className="entity-pagination-num" data-testid="pg-num" key={1} onClick={() => handleGoToPage(1)}>{1}</div>)
    }

    if (page > 3) {
        pageNums.push(<div className="entity-pagination-filler" data-testid="pg-filler" key={-1}>...</div>)
    }
    if (totalPages > 1) {
        nums.map((num) => {
            if (num >= (page - 1) && num <= (page + 1) ) {
                if (num == page) {
                    pageNums.push(<div className="entity-pagination-filler" data-testid="pg-filler" key={num}>{num}</div>)
                } else {
                    pageNums.push(<div className="entity-pagination-num" data-testid="pg-num" key={num} onClick={() => handleGoToPage(num)}>{num}</div>)
                }
            }
        })        
        if (page < totalPages - 2) {
            pageNums.push(<div className="entity-pagination-filler" data-testid="pg-filler" key={-2}>...</div>)
        }

        if (page == totalPages) {
            pageNums.push(<div className="entity-pagination-filler" data-testid="pg-filler" key={totalPages}>{totalPages}</div>)
        } else {
            pageNums.push(<div className="entity-pagination-num" data-testid="pg-num" key={totalPages} 
                onClick={() => handleGoToPage(totalPages)}>{totalPages}</div>)
        }
    }

    if (page == totalPages) {
        pageNums.push(<div key={-3}><FiChevronRight data-testid="pg-right"/></div>);
    } else {
        pageNums.push(<div key={-3}><FiChevronRight data-testid="pg-right" className="active" onClick={() => handleGoToPage(page + 1)}/></div>);
    }

    return <div className="entity-pagination">{pageNums}</div>
}

export default Pages;