import { apiSlice } from '../redux/apiSlice'

const dealTypeApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDealTypes: builder.query({
        query: () => '/deal-types',
        providesTags: (result, error, id) => [{ type: 'DealTypes', id: 'LIST' }],
        keepUnusedDataFor: 36000
    }),
    getDealType: builder.query({
        query: dealTypeId => `/deal-types/${dealTypeId}`,
        providesTags: (result, error, id) => [{ type: 'DealTypes', id }],
        keepUnusedDataFor: 36000
    }),
  }),
  overrideExisting: false,
})

export const { useGetDealTypeQuery, useGetDealTypesQuery } = dealTypeApi