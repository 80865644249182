import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { getActiveDeal, setActiveDeal } from '../../redux/deals/dealsSlice';
import { selectGeometry } from '../../redux/geometry/geometrySlice';
import { isDealChangeEnabled } from '../../helpers/deal-state-check';
import { useGetSystemsQuery } from '../../api/systemApi';

const SystemSelector = (props) => {
    const dispatch = useDispatch();
    const { data: systems, isSuccess: isSystemsSuccess } = useGetSystemsQuery();
    const geometry = useSelector(selectGeometry);
    const deal = useSelector(getActiveDeal);

    const activeSystemName = () => {
        if (props.system?.title) {
            return(props.system.title)
        }
        return "Выберите систему"
    }

    return(
        <DropdownButton
            onSelect={(i) => {
                dispatch(setActiveDeal({...deal, systemId: systems[i].id, system: systems[i].title }));
            }}
            title={activeSystemName()}
            size="sm"
            disabled={!isDealChangeEnabled(geometry)}
            className="pb-2 tarot-dropdown"
            variant={"primary"}
            data-testid="tarot-systems-selector"
        >
            {systems.map((sys, index) => {
                return(
                    <Dropdown.Item 
                        key={index} 
                        eventKey={index}
                        data-testid="tarot-system"
                    >
                        {sys.title}
                    </Dropdown.Item>
                );
            })}
        </DropdownButton>
    )
}

export default SystemSelector;