import * as actions from '../redux/socket/socket';
import io from 'socket.io-client';
import { clientAdded, clientUpdated, clientDeleteDeal, 
    clientAddDeal } from '../redux/client/clientSlice';
import { dealAdded, dealUpdated, dealDeleted } from '../redux/deals/dealsSlice';
// import { refreshUser, /*authenticate,*/ selectAccount } from '../redux/settings/settingsSlice';
// import { fetchDealTypes } from '../redux/dealTypes/dealTypesSlice';
// import { fetchSystems } from '../redux/systems/systemsSlice';
// import { fetchCards } from '../redux/cards/cardsSlice';

const socketMiddleware = () => {
    let socket = null;

    return store => next => action => {
        switch (action.type) {
            case "SC_CONNECT":

                if (socket !== null) {
                    socket.close();
                }
                socket = io(action.host);

                socket.on("disconnect", () => {
                    store.dispatch(actions.socketDisconnected())
                });

                socket.on("connect", () => {
                    console.log('socket open', socket.id);
                    store.dispatch(actions.socketConnected(socket.id))
                });

                socket.on('hello', (res) => {
                    console.log(res)
                })

                socket.on('customer_updated', (res) => {
                    const authStatus = store.getState().clients.status;
                    if (authStatus === 'succeeded') {
                        store.dispatch(clientUpdated(res));
                    }
                });

                socket.on('customer_created', (res) => {
                    const authStatus = store.getState().clients.status;
                    if (authStatus === 'succeeded') {
                        store.dispatch(clientAdded(res));
                    }
                });

                socket.on('deal_updated', (res) => {
                    const authStatus = store.getState().clients.status;
                    if (authStatus === 'succeeded') {
                        store.dispatch(dealUpdated(res));
                    }
                });

                socket.on('deal_created', (res) => {
                    const authStatus = store.getState().clients.status;
                    if (authStatus === 'succeeded') {
                        store.dispatch(dealAdded(res));
                    }
                });

                socket.on('deal_deleted', (res) => {
                    const authStatus = store.getState().clients.status;
                    if (authStatus === 'succeeded') {
                        store.dispatch(dealDeleted(res));
                    }
                });

                socket.on('user_created', (res) => {
                    const authStatus = store.getState().clients.status;
                    const authData = store.getState().settings.keycloak;
                    // if (authStatus === 'succeeded') {
                    //     store.dispatch(refreshUser(authData));
                    // }
                });

                socket.on('user_updated', (res) => {
                    const account = store.getState().settings.keycloak.account;
                    // console.log("Update received", res, account)
                    const authStatus = store.getState().settings.authenticated;
                    const authData = store.getState().settings.keycloak;
                    // const currentRole = authData?.apiToken?.user?.role?.name;
                    // const newRole = res?.role?.name;
                    let upgradeFlag = false
                    if (account?.product.name != res?.product?.name) {
                            upgradeFlag = true
                    }
                    if (authStatus === true) {
                        store.dispatch(authenticate());
                        if (upgradeFlag) {
                            // store.dispatch(fetchDealTypes(authData));
                            // store.dispatch(fetchSystems(authData));                         
                            // store.dispatch(fetchCards(authData))
                        }
                    }
                });

                break;
            case "SC_DISCONNECT":

                if (socket !== null) {
                    socket.close();
                }

                socket = null;
                console.log('socket closed');

                break;
            case "SC_AUTH":
                if (socket != null && store.getState().settings.keycloak != null) {
                    const username = store.getState().settings.keycloak?.userInfo?.sub;
                    socket.emit("join", { user: username, room: username })
                }

                break;
            default:
                return next(action);        
        }
    };
};

export default socketMiddleware;