import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { FaBars, FaSearch } from 'react-icons/fa';
import { useHistory, Link } from "react-router-dom";
import Image from 'react-bootstrap/Image';
import Spinner from 'react-bootstrap/Spinner';
import { Avatar } from 'primereact/avatar';
import { selectIsAuthenticated, setAuthenticated,
    selectAvatar } from '../redux/settings/settingsSlice';
import { showLogin, showAlert } from '../redux/modal/modalSlice';
import { useLogoutMutation } from '../api/authApi';
import { FiPlusSquare } from "react-icons/fi";
import { Typeahead } from 'react-bootstrap-typeahead';
import { useGetSelfAccountQuery } from '../api/accountApi';
import { useGetCustomersQuery } from '../api/clientApi';
import '../scss/custom.scss';
import '../css/nkp.css';
import '../css/tile.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';

const { TAROT_API_URL } = process.env;

const Header = ({ menuOpen, setMenuOpen }) => {
    const [userMenu, setUserMenu] = useState(false);
    const [singleSelections, setSingleSelections] = useState([]);
    const history = useHistory();
    const avatar = useSelector(selectAvatar);

    const [logout] = useLogoutMutation()

    const dispatch = useDispatch();
    const { data: clients, isSuccess: isClientsSuccess } = useGetCustomersQuery({ sort: "name:asc", paging: null })
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const { data: account, isSuccess: isAccountSuccess } = useGetSelfAccountQuery()

    useEffect(
        () => {
            if (singleSelections.length > 0) { 
                history.push(`/client/${singleSelections[0].id}`);
                setSingleSelections([]);
            }
        }, [singleSelections]
    )

    const handleNewDeal = () => {
        history.push(`/deal/0`);
        dispatch({ type: "API_DEAL_FLAG_SET" });
    }

    return (
        <>
        <div className='tarot-header-container'>
            <div className='menu'>
                <div id="tarology-logo" data-testid="tarology-logo" onClick={handleNewDeal}>
                    <Image src="/image/tarology.svg" id="tarot-brand" className='tarot-brand' fluid />
                </div>
            </div>
            <div className='tarot-burger-menu' data-testid='tarot-burger-menu'>
                <FaBars className='fs-burger' onClick={() => setMenuOpen(!menuOpen)} />
            </div>
            <div id="userpic" data-testid="tarot-userpic" onClick={() => setUserMenu(!userMenu)}>
                { avatar == "/image/user.png" ? 
                    <Avatar image={avatar} size="large" shape="circle" style={{ borderRadius: '50px' }}
                        data-testid="tarot-avatar" />
                    :
                    <Avatar label={account?.user_name?.substring(0,1).toUpperCase() || "U"} size="large" shape="circle"
                        data-testid="tarot-avatar" />
                }
            </div>
            <div className="tarot-search-mobile">
                <div className="search-menu" >
                    <FaSearch />
                </div>
                <div className="search-field" data-testid="tarot-client-search">
                    {isClientsSuccess?
                    <Typeahead
                        id="basic-typeahead-single"
                        labelKey="name"
                        onChange={setSingleSelections}
                        options={clients.entities}
                        placeholder="Начните вводить имя"
                        selected={singleSelections}
                        style={{ width: "210px" }}
                        className={"mb-3 search-typeahead"}
                    />
                    :
                    <div id="tarology-spinner" data-testid="tarology-spinner">
                        <Spinner animation="border" />
                    </div>
                    }
                </div> 
                <div className="tarot-new-deal" onClick={handleNewDeal} >
                    <FiPlusSquare />
            	</div>
            </div>
        </div>
        <div className={`profile-menu ${userMenu ? "active" : "inactive"}`} onMouseLeave={() => setUserMenu(false)} 
            data-testid="profile-menu">
            {!isAuthenticated &&
            <div className='menu-item' onClick={() => dispatch(showLogin())}>
                Вход
            </div>}
            {isAuthenticated &&
            <>
            <div className='menu-item'>
                <Link to="/profile">
                    Личный кабинет
                </Link>
            </div>
            <div className='menu-divider'>
                <hr/>
            </div>
            <div className='menu-item' 
                onClick={async () => {
                    try {
                        await logout().unwrap()
                        localStorage.removeItem("accessToken")
                        localStorage.removeItem("refreshToken")
                        dispatch(setAuthenticated(false))
                    } catch (err) {
                        dispatch(showAlert("Что-то пошло не так..."))
                    }
                }}
                data-testid="header-exit">
                Выход
            </div>
            </>}
        </div>
        </>
    )
}

export default Header;