import jwt_decode from 'jwt-decode';

export const isTokenExpired = (token) => {
    try {
        const decoded = jwt_decode(token);
        return (decoded.exp < Date.now() / 1000);
    }
    catch (err) {
        return false;
    }
}

export const formatDate = (dt) => {
    let d = new Date(dt);
    let result = "";
    let monthsA = "января,февраля,марта,апреля,мая,июня,июля,августа,сентября,октября,ноября,декабря"
    if (d.getDate() && d.getFullYear()) {
        result = d.getDate() + " " + monthsA.split(",")[d.getMonth()] + " " + d.getFullYear() + " г."
    }
    return result;
}

export const shiftDate = (dt, delta) => {
    let d = new Date(dt);
    d.setDate(d.getDate() + delta)
    let result = "";
    let monthsA = "января,февраля,марта,апреля,мая,июня,июля,августа,сентября,октября,ноября,декабря"
    if (d.getDate() && d.getFullYear()) {
        result = d.getDate() + " " + monthsA.split(",")[d.getMonth()] + " " + d.getFullYear() + " г."
    }
    return result;
}

export const formatDateTime = (dt) => {
    let d = new Date(dt);
    let result = "";
    let monthsA = "января,февраля,марта,апреля,мая,июня,июля,августа,сентября,октября,ноября,декабря"
    if (d.getDate() && d.getFullYear()) {
        result = d.getDate() + " " + monthsA.split(",")[d.getMonth()] + " " + d.getFullYear() + " " +
            d.getHours().toString().padStart(2, "0") + ":" + d.getMinutes().toString().padStart(2, "0");
    }
    return result;
}
