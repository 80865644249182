import { apiSlice } from '../redux/apiSlice'

const { TAROT_API_URL } = process.env;

const accountApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSelfAccount: builder.query({
      query: () => '/accounts/me',
      providesTags: (result, error, id) => [{ type: 'Account' }],
      keepUnusedDataFor: 36000
    }),
    getCatalog: builder.query({
      query: () => '/products/catalog',
      providesTags: (result, error, id) => [{ type: 'Catalog' }],
      keepUnusedDataFor: 36000
    }),
    updateAvatar: builder.mutation({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        const avatar = await fetch(_arg)
        console.log(avatar)
        if (avatar.error) return { error: avatar.error }
        const blob = await avatar.blob()
        console.log(apiSlice)
        const attachments = new FormData();
        attachments.append('file', blob, 'avatar.png');
        const token = localStorage.getItem('accessToken')
        const upload = await fetch(`${TAROT_API_URL}/medias/upload`, {
          method: "POST",
          body: attachments,
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        if (upload.error) return { error: upload.error }
        const data = await(upload.json())
        console.log(data)
        const avatar_id = data[0].id
        const result = await fetchWithBQ({
          url: "/accounts/me",
          method: "PUT",
          body: { avatar: { id: avatar_id } }
        })
        return result.data ? { data: result.data } : { error: result.error }
      },
      invalidatesTags: (result, error, { id }) => [{ type: 'Account' }],
    }),
    updateSelfData: builder.mutation({
      query(data) {
        const {avatar_url, product, avatarAlt, ...body} = data;
        return {
          url: '/accounts/me',
          method: 'PUT',
          body
        }
      },
      invalidatesTags: (result, error, { id }) => [{ type: 'Account' }],
    }),
    updateSubscription: builder.mutation({
      query(plan) {
        return {
          url: `/accounts/me/subscribe/${plan}`,
          method: 'POST'
        }
      },
      invalidatesTags: (result, error, { id }) => [{ type: 'Account' }, 
        { type: 'Articles' }, { type: 'DealTypes' }, { type: 'Cards' }, 
        { type: 'ExtraDealTypes' }, { type: 'Systems' }],
    }),
  }),
  overrideExisting: false,
})

export const { useGetSelfAccountQuery, useGetCatalogQuery, useUpdateAvatarMutation, 
  useUpdateSelfDataMutation, useUpdateSubscriptionMutation } = accountApi