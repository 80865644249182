import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { switchExtraHeader, selectExtraCards } from '../redux/geometry/geometrySlice';
import Card from 'react-bootstrap/Card';
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import ExraArticle, {} from './ExraArticle';

const ExraDealArticles = ({ position, fixed }) => {
    const dispatch = useDispatch();
    const cards = useSelector((state) => selectExtraCards(state, position));

    const handleSwitchExtraHeader = (pos, card) => {
        dispatch(switchExtraHeader({ pos: pos, card: card }));
    }

    return (
        <>
        {Object.keys(cards).map((extra, index) => {
            return(
                <div key={index}>
                {(cards[extra].card && cards[extra].position != fixed) &&
                <Card>
                    <Card.Header className="extra-header" as="h5" 
                        onClick={() => handleSwitchExtraHeader(position, cards[extra].position)}>
                        {"Доклад " + cards[extra].card.title} {cards[extra].inverted && " (перевернутая)"}
                        {(cards[extra].expanded || cards[extra].expanded == null) ?
                            <FaChevronUp style={{ marginLeft: "10px", marginTop: "-5px" }} />
                        :
                            <FaChevronDown style={{ marginLeft: "10px", marginTop: "-5px" }} />
                        }
                    </Card.Header>
                    {(cards[extra].expanded || cards[extra].expanded == null) &&
                    <Card.Body className="extra-text">
                        <ExraArticle cardId={cards[extra]?.card?.id} />
                    </Card.Body>}
                </Card>
                }
                </div>
            )
        })}
        </>
    )
}

export default ExraDealArticles;