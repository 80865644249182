import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { sortUpdated } from '../../redux/client/clientSlice';
import { BiSortAlt2, BiSortUp, BiSortDown } from "react-icons/bi";
import Button from 'react-bootstrap/Button';

import '../../scss/custom.scss';
import '../../css/nkp.css';

const SortButton = ({ field }) => {
    const sorts = useSelector(state => state.clients.sortBy);
    const sort = sorts.find(sort => sort.field == field);
    const dispatch = useDispatch();

    const changeSort = () => {
        switch(sort?.order) {
            case "asc":
                dispatch(sortUpdated({ field: field, order: "desc" }));
                break;
            case "desc":
                dispatch(sortUpdated({ field: field, order: "none" }));
                break;
            default:
                dispatch(sortUpdated({ field: field, order: "asc" }));
        }
    }

    const SortIcon = (order) => {
        switch(order) {
            case "asc":
                return <BiSortUp/>
            case "desc":
                return <BiSortDown />
            default:
                return <BiSortAlt2 />
        }
    }

    const SortTitle = (field) => {
        switch(field) {
            case "date":
                return "Сортировать по дате"
            case "deal_type":
                return "Сортировать по раскладу"
            default:
                return "Иная сортировка"
        }
    }

    return (
        <Button className="tarot-white-button mr-3" data-testid='tarot-sort-button' size="sm" onClick={changeSort}>
            { SortIcon(sort?.order) }
            { SortTitle(sort?.field) }
        </Button>    
    )
}

export default SortButton;