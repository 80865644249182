import React from 'react';
import { useDispatch } from 'react-redux';
import { FaPencilAlt } from "react-icons/fa";

const Field = (props) => {
    const {action, mouseEvent, flagVisible, enable, label, value, flag, ...rest} = props

    const dispatch = useDispatch();

    return (
        <div className='field' data-testid="tarot-field"
            onMouseEnter={() => mouseEvent(true)}
            onMouseLeave={() => mouseEvent(false)}
            {...rest}
        >
            <div className='label'>
                {label}:
            </div>
            <div className='data' data-testid='tarot-data'>
                {flag ?
                    <input 
                        type="text" 
                        value={value} 
                        className="tarot-client-field"
                        onChange={(e) => dispatch(action(e.target.value))}
                    />
                    :
                    value}
                {flagVisible && <FaPencilAlt className='edit-field' data-testid='edit-field-icon'
                    onClick={() => enable(!flag)} /> }
            </div>
        </div> 
    )
}

export default Field;