import React from 'react';
import ReactMarkdown from 'react-markdown'
import { blockquoteRenderer } from '../helpers/blockquote-renderer';
import { useGetArticleQuery } from '../api/articleApi';

const Article = (props) => {
    const { cardId } = props
    const { data: article, isSuccess } = useGetArticleQuery(cardId, { skip: cardId == null }) 

    return (
        <>
            <ReactMarkdown components={{ 
                    h2: 'h3', 
                    blockquote: ({node}) => { return(blockquoteRenderer(node)) }
                }}
                children={article?.article} 
            />
        </>
    )
}

export default Article;