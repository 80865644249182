import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { green, blue, red, orange, black } from './themes';
import prepareHeaders from '../../helpers/prepare-headers';
import { apiSlice } from '../apiSlice';

const { TAROT_KEYCLOAK_URL, TAROT_KEYCLOAK_CLIENTID, TAROT_KEYCLOAK_REALM,
  TAROT_API_URL, TAROT_PDF_URL } = process.env;

const initialState = {
    status: 'idle',
    keycloak: null,
    authenticated: false,
    account: null,
    token: null,
    tarotApiUrl: TAROT_API_URL,
    tarotPdfUrl: TAROT_PDF_URL,
    tarotKeycloakUrl: TAROT_KEYCLOAK_URL, 
    tarotKeycloakClientId: TAROT_KEYCLOAK_CLIENTID, 
    tarotKeycloakRealm: TAROT_KEYCLOAK_REALM,
    c1: "#09173A", 
    c2: "#434E93", 
    c3: "#D7DDFF",
    od_status: 'idle',
    od_error: null, 
    changeInProgress: false,
    overdue: {},
    loading: true,
}

export const fetchOverdue = createAsyncThunk('settings/fetchOverdue', async (authData) => {
  const response = await fetch(`${TAROT_API_URL}/setting`, {
    method: "Get",
    headers: prepareHeaders(authData.apiToken.jwt)
  })
  .then(res => {
    return res.json();
  })
  return response
})

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    changeTheme(state, action) {
      let theme;
      switch (action.payload) {
        case "green":
          theme = green;
          break;
        case "blue":
          theme = blue;
          break;        
        case "red":
          theme = red;
          break;        
        case "orange":
          theme = orange;
          break;        
        case "black":
          theme = black;
          break;        
        default:
          theme = green;
          break;
      }
      state.c1 = theme.c1;
      state.c2 = theme.c2;
      state.c3 = theme.c3;
      localStorage.setItem("theme", action.payload)
    },
    setUserAvatar(state, action) {
      const avatar = action.payload;
      let currentUser = state.account;
      if (currentUser) {
        currentUser.avatarAlt = avatar;
      }
    },
    setUserUsername(state, action) {
      let user_name = action.payload;
      let currentUser = state.account;
      if (currentUser) {
        currentUser.user_name = user_name;
      }
    },
    setUserCountry(state, action) {
      let country = action.payload;
      let currentUser = state.account;
      if (currentUser) {
        currentUser.country = country;
      }
    },
    setUserEmail(state, action) {
      let email = action.payload;
      let currentUser = state.account;
      if (currentUser) {
        currentUser.email = email;
      }
    },
    setUserGivenName(state, action) {
      let name = action.payload;
      let currentUser = state.account;
      if (currentUser) {
        currentUser.name = name;
      }
    },
    setUserFamilyName(state, action) {
      let surname = action.payload;
      let currentUser = state.account;
      if (currentUser) {
        currentUser.surname = surname;
      }
    },
    setUserPhone(state, action) {
      let phone = action.payload;
      let currentUser = state.account;
      if (currentUser) {
        currentUser.phone = phone;
      }
    },
    stopLoading(state, action) {
      state.loading = false;
    },
    setAuthenticated(state, action) {
      state.authenticated = action.payload;
    },
    fillAccountPermissions(state, action) {
      let product = state.account.product;
      let catalog = [].concat(state.catalog?.product)
      let user_item = catalog.find(rec => rec.guid == product.guid)
      let ACCESS_DEAL_TYPE = []
      let ACCESS_DECK = []
      user_item.ACCESS_DEAL_TYPE.forEach(element => {
        ACCESS_DEAL_TYPE.push(element)
      });
      user_item.ACCESS_DECK.forEach(element => {
        ACCESS_DECK.push(element)
      });
      product.ACCESS_DEAL_TYPE = ACCESS_DEAL_TYPE
      product.ACCESS_DECK = ACCESS_DECK
      state.catalog_status = 'enriched'
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchOverdue.pending, (state, action) => {
        state.od_status = 'loading'
      })
      .addCase(fetchOverdue.fulfilled, (state, action) => {
        state.od_status = 'succeeded'
        state.overdue = action.payload
      })
      .addCase(fetchOverdue.rejected, (state, action) => {
        state.od_status = 'failed'
        state.od_error = action.error.message
      })
      .addMatcher(apiSlice.endpoints.login.matchFulfilled, 
      (state, { payload }) => {
        localStorage.setItem('accessToken', payload.access_token)
        localStorage.setItem('refreshToken', payload.refresh_token)
        state.authenticated = true
      })
      .addMatcher(apiSlice.endpoints.getSelfAccount.matchFulfilled, 
        (state, { payload }) => {
          state.account = payload
        })
    }
})

export const { changeTheme, setUserAvatar, setUserEmail, setUserGivenName, 
  setUserFamilyName, setUserPhone, setUserCountry, setUserUsername, 
  stopLoading, setAuthenticated, fillAccountPermissions } = settingsSlice.actions
  
export default settingsSlice.reducer

export const getKeycloak = state => state.settings.keycloak;

export const selectAvatar = state => {
  if (state.settings.account?.avatarAlt) {
    return `${state.settings.account?.avatarAlt}`;
  }

  if (state.settings.account?.avatar_url) {
    return `${TAROT_API_URL}/medias/${state.settings.account?.avatar_url}`;
  }

  return "/image/user.png";
}

export const selectAccount = state => state.settings.account;

export const selectIsAuthenticated = state => state.settings.authenticated;

export const selectProduct = state => state.settings.keycloak?.account?.product;

export const selectTheme = state => {
  return { "c1": state.settings.c1, "c2": state.settings.c2, "c3": state.settings.c3 };
}