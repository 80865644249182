import { apiSlice } from '../redux/apiSlice'

const clientApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCustomers: builder.query({
      query: ({sort, paging}) => `/customers?sort=${sort}&${paging}`,
      providesTags: (result, error, id) => [{ type: 'Customers', id: 'LIST' }],
      keepUnusedDataFor: 36000
    }),
    getCustomer: builder.query({
      query: id => `/customers/${id}`,
      providesTags: (result, error, id) => [{ type: 'Customers', id }],
      keepUnusedDataFor: 36000
    }),
    getFavoriteCustomers: builder.query({
      query: () => `/customers/favorites`,
      providesTags: (result, error, id) => [{ type: 'Customers', id: 'FAVS' }],
      keepUnusedDataFor: 36000
    }),
    addCustomer: builder.mutation({
      query(data) {
      const {id, ...body} = data;
        return {
          url: `/customers`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: [{ type: 'Customers', id: 'FAVS' },
                        { type: 'Customers', id: 'LIST' }]
    }),
    updateCustomer: builder.mutation({
      query(data) {
        const {id, ...body} = data;
        return {
          url: `/customers/${id}`,
          method: 'PUT',
          body
        }
      },
      invalidatesTags: (result, error, { id }) => [ { type: 'Customers', id }, 
                                                    { type: 'Customers', id: 'FAVS' },
                                                    { type: 'Customers', id: 'LIST' }],
    }),
    deleteCustomer: builder.mutation({
      query(id) {
        return {
          url: `/customers/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: (result, error, id) => [ { type: 'Customers', id }, 
                                                { type: 'Customers', id: 'FAVS' },
                                                { type: 'Customers', id: 'LIST' }],
    }),
  }),
  overrideExisting: false,
})

export const { useGetCustomerQuery, useGetCustomersQuery, useGetFavoriteCustomersQuery,
  useAddCustomerMutation, useUpdateCustomerMutation, useDeleteCustomerMutation } = clientApi