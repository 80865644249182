import React, { useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { clientSwapFavorite, switchEditMode, clearNewClientFlag, 
    setConfirmDeleteClient, activeClientLoaded, 
    getActiveClient } from '../redux/client/clientSlice';
import { selectProduct } from '../redux/settings/settingsSlice';
import { ExpandedContext } from '../expanded-context';
import { FaStar, FaRegStar, FaPencilAlt } from "react-icons/fa";
import { useHistory, useParams } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import SortButton from './control-client/SortButton';
import Spinner from 'react-bootstrap/Spinner';
import Toast from 'react-bootstrap/Toast';
import ConfirmDeleteClientModal from '../modals/ConfirmDeleteClientModal';
import { AppError } from './AppError';
import BirthdaySelector from './control-client/BirthdaySelector';
import SexSelector from './control-client/SexSelector';
import EmailControl from './control-client/EmailControl';
import PhoneControl from './control-client/PhoneControl';
import NameControl from './control-client/NameControl';
import ClientSaveButton from './control-client/ClientSaveButton';
import ClientDeleteButton from './control-client/ClientDeleteButton';
import ClientDeals from './control-client/ClientDeals';
import { useGetSelfAccountQuery } from '../api/accountApi';
import { useGetCustomerQuery, useDeleteCustomerMutation } from '../api/clientApi';

import '../scss/custom.scss';
import '../css/tile.css';
import '../css/nkp.css';

const Client = (props) => {
    const [expanded] = useContext(ExpandedContext);
    const history = useHistory();
    const { clientId } = useParams();
    const dispatch = useDispatch();
    const newClientStatus = useSelector(state => state.clients.newclient);
    const product = useSelector(selectProduct);
    const clientToDelete = useSelector(state => state.clients.clientToDelete);
    const confirmDeleteClientVisible = useSelector(state => state.clients.confirmClientDeleteVisible);
    const activeClient = useSelector(getActiveClient)
    const [toast, setToast] = useState({});
    const [showToast, setShowToast] = useState(false);
    const [deleteCustomer, { isDeleteLoading }] = useDeleteCustomerMutation()

    const { data: account, isSuccess: isAccountSuccess } = useGetSelfAccountQuery()
    const { data: client, isSuccess: isClientSuccess } = useGetCustomerQuery(clientId, { skip: !clientId || clientId == 0 })

    useEffect(
        () => {
            if (product?.phase == "SUSPENDED" || product?.phase == "BLOCKED" || product?.name == "DEMO") {
                history.push("/profile")
            }
        }, [account]
    )

    useEffect(
        () => {
            if (newClientStatus?.id) {
                history.push(`/client/${newClientStatus.id}`)
                dispatch(clearNewClientFlag())
            }            
        }, [newClientStatus]
    );

    useEffect(
        () => {
            if (clientId != 0) {
                dispatch(activeClientLoaded(client))
            } else {
                dispatch(activeClientLoaded({ id: 0 }))
            }
        }, [client, clientId]
    )

    const handleDeleteClientConfirm = async () => {
        if (clientToDelete.id == clientId) {
            try {
                await deleteCustomer(clientId).unwrap()
                dispatch(activeClientLoaded({}))
                dispatch(setConfirmDeleteClient(false))
                history.push(`/client/0`)
            } catch {
                setToast({
                title: 'Не получилось...',
                description: "Не удалось удалить клиента, пожалуйста попробуйте еще раз.",
                status: 'error'
                })
                setShowToast(true)
            }
        }
    }

    return (
        <div className={expanded ? "tarot-content-client-expanded" : "tarot-content-client"}>
            {(!isAccountSuccess || (!isClientSuccess && clientId != 0)) ?
            <div id="tarology-spinner" data-testid="tarology-spinner">
                <Spinner animation="border" />
            </div>
            :
            <>
            <div className="content mt-3 pt-3" id="customer-profile">
                <NameControl className="profile-title mt-3" client={activeClient} />
                <div className="tarot-client-icon-bed">
                    <FaPencilAlt size={20} className="tarot-client-icon" 
                        data-testid='tarot-edit-mode-selector'
                        onClick={() => { dispatch(switchEditMode()) }}/> 
                    {activeClient?.favorite ?
                    <FaStar size={30} className="tarot-client-icon" 
                        data-testid='tarot-favicon'
                        onClick={() => dispatch(clientSwapFavorite())} />
                    :
                    <FaRegStar size={30} className="tarot-client-icon" 
                        data-testid='tarot-favicon'
                        onClick={() => dispatch(clientSwapFavorite())} />}
                </div>
                <div className="profile-section">
                    <Row>
                        <Col>
                            <AppError module="SexSelector">
                                <SexSelector client={activeClient} />
                            </AppError>
                            <AppError module="BirthdaySelector">
                                <BirthdaySelector client={activeClient} />
                            </AppError>
                            <AppError module="EmailControl">
                                <EmailControl client={activeClient} />
                            </AppError>
                            <AppError module="PhoneControl">
                                <PhoneControl client={activeClient} />
                            </AppError>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='tarot-client-buttons'>
                            <div className="mt-3">
                                <AppError module="ClientSaveButton">
                                    <ClientSaveButton client={activeClient} />
                                </AppError>
                                <AppError module="ClientDeleteButton">
                                    <ClientDeleteButton client={activeClient} />
                                </AppError>
                                <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide>
                                    <Toast.Header>
                                        <strong className="me-auto">{toast?.title}</strong>
                                    </Toast.Header>
                                    <Toast.Body>{toast?.description}</Toast.Body>
                                </Toast>
                            </div>
                        </Col>
                    </Row>
                </div>

                <div className='deals-section'>
                    <Row>
                        <Col className='tarot-client-buttons'>
                            <div className="mt-3">
                                <AppError module="SortButtons">
                                    <SortButton field="date" />
                                    <SortButton field="deal_type" />
                                </AppError>
                            </div>
                        </Col>
                    </Row>

                    <AppError module="ClientDeals">
                        <ClientDeals client={client} />
                    </AppError>
                </div>
            </div>

            <ConfirmDeleteClientModal 
                visible={confirmDeleteClientVisible} 
                setVisible={(e) => dispatch(setConfirmDeleteClient(e))}
                client={client}
                delete={handleDeleteClientConfirm}
            />
        </>}
        </div>
    );
}

export default Client;