import React, { useState, useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { getKeycloak, changeTheme, selectAvatar } from '../redux/settings/settingsSlice';
import Spinner from 'react-bootstrap/Spinner';
import { socketAuthenticated } from '../redux/socket/socket';
import { Link, useLocation, useHistory } from "react-router-dom";
import { ProSidebar, SidebarContent, SidebarHeader, Menu, 
    MenuItem, SubMenu } from 'react-pro-sidebar';
import { FaHandSparkles, FaArchive, FaAngleDoubleRight, 
    FaAngleDoubleLeft, FaUsers, FaSearch, FaTools } from "react-icons/fa";
import { BiExit } from 'react-icons/bi';
import { ExpandedContext } from '../expanded-context';
import { Typeahead } from 'react-bootstrap-typeahead';
import parseLocation from '../helpers/parse-location';
import * as utils from '../helpers/utils';
import { Avatar } from 'primereact/avatar';
import { useGetSelfAccountQuery } from '../api/accountApi';
import { useGetCustomersQuery, useGetFavoriteCustomersQuery } from '../api/clientApi';
import { useGetDealsOfFavoritesQuery } from '../api/dealApi';

import "../css/sidebar.css";
import 'react-pro-sidebar/dist/css/styles.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';

const { TAROT_API_URL } = process.env;

const Sidebar = ({ menuOpen, setMenuOpen }) => {
	const [expanded, setExpanded] = useContext(ExpandedContext);
    const [singleSelections, setSingleSelections] = useState([]);
    const history = useHistory();

    const dispatch = useDispatch();
    const authStatus = useSelector(state => state.settings.authenticated);
    const keycloak = useSelector(getKeycloak);
    const { data: account, isSuccess: isAccountSuccess } = useGetSelfAccountQuery()
    const { data: clients, isSuccess: isClientsSuccess } = useGetCustomersQuery({ sort: "name:asc", paging: null })
    const { data: favoriteClients, isSuccess: isFavClientsSuccess } = useGetFavoriteCustomersQuery()
    const { data: deals, isSuccess: isDealsSuccess } = useGetDealsOfFavoritesQuery()
    const avatar = useSelector(selectAvatar);

    const location = useLocation();

    useEffect(() => {
        if (authStatus === 'succeeded') {
            dispatch(socketAuthenticated())
        }
    }, [keycloak])

    const menuIconClick = () => {
        setExpanded(!expanded);
    };

    useEffect(
        () => {
            if (singleSelections.length > 0) { 
                history.push(`/client/${singleSelections[0].id}`);
                setSingleSelections([]);
            }
        }, [singleSelections]
    )

    const handleNewDeal = () => {
        history.push(`/deal/0`);
        dispatch({ type: "API_DEAL_FLAG_SET" });
        setMenuOpen(false);
    }

    const handleThemeChange = (theme) => {
        dispatch(changeTheme(theme));
    }

    const handleSubcribe = () => {
        history.push("/subscribe")
        setMenuOpen(false);
    }

    const handleFollowMenu = (url) => {
        if (account?.product?.name != 'DEMO') {
            history.push(url)
        }
        setMenuOpen(false);
    }

    const handleFollowMenuDemo = (url) => {
        history.push(url)
        setMenuOpen(false);
    }
    
    return (
        <>
        <div id="sidebar" className={`${menuOpen ? "menu-expanded" : ""}`}>
        <ProSidebar collapsed={!expanded}>
            <SidebarHeader>
                <div className={!expanded ? "closemenu-collapse" : "closemenu"} 
                    data-testid="expand-selection" onClick={menuIconClick}>
                    {!expanded ? (
                        <FaAngleDoubleRight/>
                    ) : (
                        <FaAngleDoubleLeft/>
                    )}
                </div>
            </SidebarHeader>
            <div id="userpic-small-mobile">
                { avatar && <Avatar image={avatar} size="large" shape="circle"/> }
            </div>

            <SidebarContent>
                <Menu iconShape="square">
                    <MenuItem active={parseLocation(location).location == "deal" && parseLocation(location).id == 0 ? true : false} 
                        onClick={handleNewDeal} icon={<FaHandSparkles />} data-testid="tarot-menu-item" >
                        Новый расклад
                    </MenuItem>
                    <SubMenu title="Прошлые расклады" className='tarot-menu-item-big' data-testid="tarot-menu-item" 
                        icon={<FaArchive />}>
                        {!isDealsSuccess ?
                        <div id="tarology-spinner">
                            <Spinner animation="border" />
                        </div>
                        :
                        <>
                        {favoriteClients?.map((customer, index) => {
                            let shortName = ""
                            let nameParts = customer.name.split(" ");
                            if (nameParts.length > 1) {
                                shortName = nameParts[0] + " " + nameParts[1].substring(0, 1) + "."
                            } else {
                                shortName = customer.name
                            }
                            return (
                                <SubMenu key={index} title={shortName}>
                                    {deals.map((deal, ix) => {
                                        if (deal.customer_id == customer.id) {
                                            return (
                                                    <MenuItem key={ix} 
                                                        active={parseLocation(location).location == "deal" && parseLocation(location).id == deal.id ? 
                                                        true : false}>
                                                        <Link to={`/deal/${deal.id}`}>
                                                            <div><small><strong>{deal.deal_type_name}</strong></small></div>
                                                            <div><small><i>{utils.formatDate(new Date(deal.date))}</i></small></div>
                                                        </Link>
                                                    </MenuItem>    
                                            )    
                                        }
                                    })}
                                </SubMenu>
                            )
                        })}
                        </>}
                    </SubMenu>
                    <MenuItem className='tarot-menu-item-small' icon={<FaUsers />} data-testid="tarot-menu-item"
                        onClick={() => handleFollowMenu("/clients")}>
                        Клиенты и расклады
                    </MenuItem>
                    <SubMenu title="Клиенты" className='tarot-menu-item-big' icon={<FaUsers />} 
                        data-testid="tarot-menu-item">
                        {account?.product?.name != 'DEMO' &&
                        <>
                        <MenuItem>
                            <Link to={`/clients`} data-testid="tarot-client-all">
                                Все клиенты
                            </Link>    
                        </MenuItem>
                        <MenuItem>
                            <Link to={`/client/0`} data-testid="tarot-client-new">
                                <i>&lt; Новый клиент &gt;</i>
                            </Link>    
                        </MenuItem>
                        {favoriteClients?.map((customer) => {
                            let shortName = ""
                            let nameParts = customer.name.split(" ");
                            if (nameParts.length > 1) {
                                shortName = nameParts[0] + " " + nameParts[1].substring(0, 1) + "."
                            } else {
                                shortName = customer.name
                            }
                            return (
                                <MenuItem key={customer.id} 
                                    active={parseLocation(location).location == "client" && parseLocation(location).id == customer.id ? 
                                    true : false}>
                                    <Link to={`/client/${customer.id}`} data-testid="tarot-client-menu">
                                        {shortName}
                                    </Link>    
                                </MenuItem>
                            )
                        })}
                        </>}
                    </SubMenu>
                    <MenuItem className='tarot-menu-item-small'  icon={<FaTools />} data-testid="tarot-menu-item"
                        onClick={() => handleFollowMenuDemo("/profile")}>v
                        Личный кабинет
                    </MenuItem>
                    <MenuItem className='tarot-menu-item-small' icon={<BiExit />} data-testid="tarot-menu-item"
                        /*onClick={() => dispatch(logout())}*/>
                        Выход
                    </MenuItem>
                    <SubMenu title="Настройки" className='tarot-menu-item-big' data-testid="tarot-menu-item"
                        icon={<FaTools />}>
                        <SubMenu title="Тема оформления">
                            <MenuItem onClick={() => handleThemeChange("green")}>
                                Зеленая                
                            </MenuItem>    
                            <MenuItem onClick={() => handleThemeChange("blue")}>
                                Синяя                
                            </MenuItem>    
                            <MenuItem onClick={() => handleThemeChange("red")}>
                                Красная                
                            </MenuItem>    
                            <MenuItem onClick={() => handleThemeChange("orange")}>
                                Оранжевая                
                            </MenuItem>    
                            <MenuItem onClick={() => handleThemeChange("black")}>
                                Черно-белая                
                            </MenuItem>    
                        </SubMenu>
                    </SubMenu>
                </Menu>
                {!isClientsSuccess ?
                <div id="tarology-spinner">
                    <Spinner animation="border" />
                </div>
                :            
                <div id="tarot-search">
                    <div className={expanded ? "search-menu" : "search-menu-collapsed"} >
                        <FaSearch style={{ color: "#e3efe1" }} />
                    </div>
                    <div className={expanded ? "search-field" : "search-menu-popper"} data-testid="tarot-customer-search">
                        <Typeahead
                            id="basic-typeahead-single"
                            labelKey="name"
                            onChange={setSingleSelections}
                            options={clients.entities}
                            placeholder="Начните вводить имя"
                            selected={singleSelections}
                            style={{ width: expanded && "210px" }}
                            className={expanded ? "mb-3" : "mb-3 search-typeahead"}
                        />
                    </div> 
                </div>}
                {account?.product?.name == 'DEMO' &&
                <div id="tarot-demo" data-testid="tarot-demo">
                    <span>
                        Приложение работает в режиме <b>демо-версии</b>. 
                        Для доступа к полному функционалу пожалуйста 
                        <a href="#" onClick={handleSubcribe} data-testid="subscribe-button"> <strong>оформите подписку</strong></a>.
                    </span>
                </div>}
            </SidebarContent>
        </ProSidebar>
        </div>
        </>
    )
}

export default Sidebar;