export const getStageDimensions = (width, height, expanded, x, y) => {
    let dimensions = {}

    let stageWidth, stageHeight;

    if (width > 720) {
        if (expanded) {
            stageWidth = width > 880 ? width - 680 : 200;
        } else {
            stageWidth = width - 440;
        }
        stageHeight = (y / x) * stageWidth * 1.5;
        if (stageHeight > stageWidth * 1.5) {
            stageHeight = stageWidth * 1.5;
        }
        if (stageHeight > height - 80) {
            stageHeight = height - 80;
        }
    
        dimensions.width = stageWidth;
        dimensions.height = stageHeight;    
    } else {
        stageWidth = width - 80;
        stageHeight = height - 110;
   
        dimensions.width = stageWidth;
        dimensions.height = stageHeight;    
    }

    return dimensions;
}

export const getStageDimensionsVertical = (width, x, y) => {
    let dimensions = {} 

    let stageHeight;

    stageHeight = (y / x) * width * 1.7;

    dimensions.width = width;
    dimensions.height = stageHeight;

    return dimensions;
}

const findRotated = (cards, maxX) => {
    let result = false;
    cards.map((card) => {
        if (card.rotate != "C" && card.rotate != "C180" && card.x == 1 && card.x == maxX) {
            result = true;
        }
    })
    return result;
}

export const getCardDimensions = (stage, x, y, margins, outerX, outerY) => {
    let geo = {};

    geo.outerMarginRateX = outerX;
    geo.outerMarginRateY = outerY;
    geo.marginRate = margins;

    let tmpWidth = stage.width / (x + (x - 1) * geo.marginRate + geo.outerMarginRateX * 2);
    let tmpHeight = stage.height / (y + (y - 1) * geo.marginRate / 1.5 + geo.outerMarginRateY * 2 / 1.5);

    if (tmpWidth * 1.5 <= tmpHeight) {
        geo.cardWidth = tmpWidth;
        geo.cardHeight = tmpWidth * 1.5;
        geo.margin = tmpWidth * geo.marginRate;
        geo.outerMarginX = tmpWidth * geo.outerMarginRateX;
        geo.outerMarginY = tmpWidth * geo.outerMarginRateY; 
        geo.yOffset = (stage.height - (geo.outerMarginY * 2 + y * geo.cardHeight + (y - 1) * geo.margin)) / 2;
        geo.xOffset = 0;
    } else {
        geo.cardWidth = tmpHeight / 1.5;
        geo.cardHeight = tmpHeight;
        geo.margin = tmpHeight * geo.marginRate / 1.5;
        geo.outerMarginX = tmpHeight * geo.outerMarginRateX / 1.5;
        geo.outerMarginY = tmpHeight * geo.outerMarginRateY / 1.5; 
        geo.yOffset = 0;
        geo.xOffset = (stage.width - (geo.outerMarginX * 2 + x * geo.cardWidth + (x - 1) * geo.margin)) / 2;
    }

    return geo;
}

export const applyRotation = (pos) => {
    let rotation, x, y, offX, offY;
    if(pos) {
        switch (pos.rotate) {
            case "L45":
                if (pos?.inverted) {
                    rotation = 140;
                } else {
                    rotation = -40;
                }
                x = pos.x;
                y = pos.y + pos.height / 2;
                offX = pos.width / 2;
                offY = pos.height / 2;
                break;
            case "R45":
                if (pos?.inverted) {
                    rotation = -140;
                } else {
                    rotation = 40;
                }
                x = pos.x + pos.width;
                y = pos.y + pos.height / 2;
                offX = pos.width / 2;
                offY = pos.height / 2;
                break;
            case "L90":
                if (pos?.inverted) {
                    rotation = 90;
                } else {
                    rotation = -90;
                }
                x = pos.x + pos.width / 2;
                y = pos.y + pos.height / 2;
                offX = pos.width / 2;
                offY = pos.height / 2;
                break;
            case "R90":
                if (pos?.inverted) {
                    rotation = -90;
                } else {
                    rotation = 90;
                }
                x = pos.x + pos.width / 2;
                y = pos.y + pos.height / 2;
                offX = pos.width / 2;
                offY = pos.height / 2;
                break;
            case "C180":
                if (pos?.inverted) {
                    rotation = 0;
                } else {
                    rotation = 180;
                }
                x = pos.x + pos.width / 2;
                y = pos.y + pos.height / 2;
                offX = pos.width / 2;
                offY = pos.height / 2;
                break;
            default:
                if (pos?.inverted) {
                    rotation = 180;
                } else {
                    rotation = 0;
                }
                x = pos.x + pos.width / 2;
                y = pos.y + pos.height / 2;
                offX = pos.width / 2;
                offY = pos.height / 2;
                break;
            }
        }
    return {rotation, x, y, offX, offY};
}

export const calculatePositions = (dealCards, width, height, expanded, vertical) => {
    let maxX = Math.max.apply(Math, dealCards.map(function(o) { return o.x; }))
    let maxY = Math.max.apply(Math, dealCards.map(function(o) { return o.y; }))

    let stage, geo;
    
    if (vertical) {
        let ofX = 0.3;
        stage = getStageDimensionsVertical(width, maxX, maxY);
        if (findRotated(dealCards, maxX)) { ofX = 1.1; stage.height = stage.height / 1.7 }
        geo = getCardDimensions(stage, maxX, maxY, 1, ofX, 0.1);
    } else {
        stage = getStageDimensions(width, height, expanded, maxX, maxY);
        geo = getCardDimensions(stage, maxX, maxY, 1, 1, 0.5);
    }

    let newPositions = [];
    geo.stage = stage;
    dealCards.map((card) => {
        let newCard;
        newCard = {}
        // newCard.inverted = false;
        newCard.position = card.position;
        newCard.rotate = card.rotate;
        newCard.id = card.id;
        newCard.x = geo.outerMarginX + (card.x - 1) * (geo.cardWidth + geo.margin) + geo.xOffset;
        newCard.y = geo.outerMarginY + (card.y - 1) * (geo.cardHeight + geo.margin) + geo.yOffset;
        newCard.width = geo.cardWidth;
        newCard.height = geo.cardHeight;
        newPositions.push(newCard);	
    });    

    geo.positions = newPositions;

    return geo;
}

export const adjustStageDimensions = (stage) => {
    let width = 100
    let height =100
    if (stage && !isNaN(stage?.width)) { width = stage.width }
    if (stage && !isNaN(stage?.height)) { height = stage.height }

    return { width: width, height: height }
}
