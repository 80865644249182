import React from 'react';
import Form from 'react-bootstrap/Form';
import { AppError } from '../AppError';
import SystemSelector from './SystemSelector';
import DealTypeSelector from './DealTypeSelector';
import CustomerSelector from './CustomerSelector';
import DateSelector from './DateSelector';
import ButtonSaveDeal from './ButtonSaveDeal';
import ButtonExportPDF from './ButtonExportPDF';
import ButtonAddComment from './ButtonAddComment';
import ButtonDeleteDeal from './ButtonDeleteDeal';
import QuestionArea from './QuestionArea';

const ControlPanel = (props) => {
    const readOnly = props.readOnly

    return (
        <Form>
            <AppError module="SystemSelector">
                <SystemSelector system={props.system} />
            </AppError>

            <AppError module="DealTypeSelector">
                <DealTypeSelector />
            </AppError>

            <AppError module="CustomerSelector">
                <CustomerSelector />
            </AppError>

            <AppError module="DateSelector">
                <DateSelector />
            </AppError>

            <AppError module="QuestionArea">
                <QuestionArea />
            </AppError>

            <AppError module="ButtonSaveDeal">
                <ButtonSaveDeal />
            </AppError>
            <AppError module="ButtonExportPDF">
                <ButtonExportPDF />
            </AppError>
            <AppError module="ButtonAddComment">
                <ButtonAddComment {...props} />
            </AppError>
            <AppError module="ButtonDeleteDeal">
                <ButtonDeleteDeal disabled={readOnly} />
            </AppError>
        </Form>
    )
}

export default ControlPanel;