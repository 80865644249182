import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Typeahead } from 'react-bootstrap-typeahead';

import 'react-bootstrap-typeahead/css/Typeahead.css';

const ModalChooseCard = (props) => {
    return (
        <Modal size={"sm"} show={props.visible} animation={false} 
            id="tarot-select-modal" onHide={props.close}>
            <Modal.Body>
            <Typeahead
                id="basic-typeahead-single"
                labelKey="name"
                onChange={props.handleChange}
                options={props.cards || []}
                placeholder="Начните вводить название карты"
                selected={props.selected}
                autoFocus={true}
                highlightOnlyResult={true}
                className="mb-3"
            />
            </Modal.Body>
        </Modal>
    )
}

export default ModalChooseCard;